import { Map, Record } from "immutable";
import { ActionType } from "typesafe-actions";

import { ModalActionTypes } from "src/action-types/Modal";
import * as actions from "src/actions/Modal";

export type ModalActions = ActionType<typeof actions>;

export interface IModalState {
  modals: Map<string, boolean>;
}

export type ModalState = Record<IModalState>;

const ModalState = Record<IModalState>({
  modals: Map(),
});

const initialState: ModalState = new ModalState();

export default function modal(
  state: ModalState = initialState,
  action: ModalActions
): ModalState {
  switch (action.type) {
    case ModalActionTypes.TOGGLE:
      const indexOfValue = state
        .get("modals", null)
        .valueSeq()
        .toArray()
        .indexOf(true);
      const target = state.get("modals", null).keySeq().toArray()[indexOfValue];
      if (indexOfValue >= 0) {
        return state.withMutations((mutable) => {
          mutable.set(
            "modals",
            state
              .get("modals", null)
              .update(target, (value) => false)
              .set(action.payload!.target, action.payload!.value)
          );
        });
      } else {
        return state.set(
          "modals",
          state
            .get("modals", null)
            .set(action.payload!.target, action.payload!.value)
        );
      }
    default:
      return state;
  }
}
