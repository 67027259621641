import { Record } from "immutable";
import { WebSocketActionTypes } from "src/action-types/WebSocket";
import { Action } from "redux";

export enum WebSocketStatusEnum {
  NOT_CONNECTED = "not-connected",
  CONNECTING = "connecting",
  CONNECTED = "connected",
}

export interface IWebSocketState {
  status: WebSocketStatusEnum;
  isFetching: boolean;
}

export type WebSocketState = Record<IWebSocketState>;

const WebSocketState = Record<IWebSocketState>({
  status: WebSocketStatusEnum.NOT_CONNECTED,
  isFetching: false,
});

const initialState = new WebSocketState();

export default function webSocketReducer(
  state: WebSocketState = initialState,
  action: Action
): WebSocketState {
  switch (action.type) {
    case WebSocketActionTypes.CONNECT:
      return state.set("status", WebSocketStatusEnum.CONNECTING);

    case WebSocketActionTypes.CONNECTED:
      return state.set("status", WebSocketStatusEnum.CONNECTED);

    case WebSocketActionTypes.DISCONNECTED:
      return state.set("status", WebSocketStatusEnum.NOT_CONNECTED);

    case WebSocketActionTypes.SEND:
      return state.set("isFetching", true);

    case WebSocketActionTypes.MESSAGE:
      return state.set("isFetching", false);

    default:
      return state;
  }
}
