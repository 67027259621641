import { fromJS, List, Record, Map } from "immutable";
import { ActionType } from "typesafe-actions";
import * as actions from "src/actions/Messages";
import { MessagesActionTypes } from "src/action-types/Messages";
import {
  IAutocompleteClients,
  IAutocompleteServices,
  IAutocompleteReceivers,
  IMessagesListElement,
  IMessageDetailsData,
  INewMessageData,
} from "src/actions/Messages";
import { ImmutableMap } from "src/helpers/ImmutableHelpers";
import * as moment from "moment";
// import { MessagesTab } from 'src/components/App/Messages/MessagesListHeader';
// import { IAttachmentsState } from 'src/components/App/Messages/NewMessage/MessageControls';

export type MessagesActions = ActionType<typeof actions>;

export interface IMessagesListFilters {
  sender: string;
  receiver: string;
  sid: string;
  title: string;
  created_at: string;
}

export interface IMessagesState {
  isServiceSelectionModeActive: boolean;
  isClientSelectionModeActive: boolean;
  isNewMessageCreatorShown: boolean;
  autocompleteServicesData: List<IAutocompleteServices>;
  autocompleteClientsData: List<IAutocompleteClients>;
  messagesList: List<IMessagesListElement>;
  selectedServices: List<number>;
  selectedClients: List<number>;
  activeMessageId: number | null;
  autocompleteReceiversData: List<IAutocompleteReceivers>;
  messagesTemplates: any;
  messageDetails: IMessageDetailsData | null;
  triggerResetList: boolean;
  newMessageData: ImmutableMap<INewMessageData>;
  activeTab: any | null;
  isMailConfigShown: boolean;
  mailConfig: any;
  listFilters: ImmutableMap<IMessagesListFilters>;
  attachmentsState: any;
  paymentDue: any;
  paymentType: any;
  frequencyType: any;
  messagesListLoading: boolean;
  recieversList: any;
}

export type MessagesState = Record<IMessagesState>;

export const initialNewMessageData: ImmutableMap<INewMessageData> = Map({
  relatedServices: [],
  relatedUsers: [],
  receivers: [],
  subject: "",
  paymentTopic: "",
  message: "",
  typeId: 1,
  relatedDocuments: [],
  payments: null,
  draftId: null,
});

const initialFilters = Map({
  sender: "",
  receiver: "",
  sid: "",
  title: "",
  created_at: "",
});

export const initialAttachmentsState: any = {
  attachmentProgresses: [],
  attachmentsNames: [],
  attachmentsIds: [],
};

const MessagesState = Record<IMessagesState>({
  isServiceSelectionModeActive: false,
  isClientSelectionModeActive: false,
  isNewMessageCreatorShown: false,
  messagesList: List(),
  messagesListLoading: false,
  autocompleteServicesData: List(),
  autocompleteClientsData: List(),
  selectedServices: List(),
  selectedClients: List(),
  activeMessageId: null,
  messageDetails: null,
  autocompleteReceiversData: List(),
  messagesTemplates: [],
  messageActiveTemplate: null,
  triggerResetList: false,
  newMessageData: initialNewMessageData,
  activeTab: null,
  mailConfig: null,
  isMailConfigShown: false,
  listFilters: initialFilters,
  attachmentsState: initialAttachmentsState,
  paymentDue: [],
  paymentType: [],
  frequencyType: [],
  recieversList: [],
});

enum SELECTION_LISTS {
  SERVICES = "selectedServices",
  CLIENTS = "selectedClients",
}

const initialState = new MessagesState();

const handleToggleSelection = function (
  state: MessagesState,
  id: number,
  fieldName: SELECTION_LISTS
) {
  const list = state.get(fieldName);
  const index = list.findIndex((savedId) => savedId === id);
  if (index > -1) {
    return state.set(fieldName, list.delete(index));
  }
  return state.set(fieldName, list.push(id));
};

export default function messages(
  state: MessagesState = initialState,
  action: MessagesActions
): MessagesState {
  switch (action.type) {
    case MessagesActionTypes.TOGGLE_SERVICE_SELECTION_MODE:
      return state.set("isServiceSelectionModeActive", action.payload);
    case MessagesActionTypes.TOGGLE_CLIENT_SELECTION_MODE:
      return state.set("isClientSelectionModeActive", action.payload);
    case MessagesActionTypes.TOGGLE_SERVICE_SELECTION:
      return handleToggleSelection(
        state,
        action.payload,
        SELECTION_LISTS.SERVICES
      );
    case MessagesActionTypes.TOGGLE_CLIENT_SELECTION:
      return handleToggleSelection(
        state,
        action.payload,
        SELECTION_LISTS.CLIENTS
      );
    case MessagesActionTypes.TOGGLE_NEW_MESSAGE_CREATOR:
      return state.set("isNewMessageCreatorShown", action.payload);
    case MessagesActionTypes.CLEAR_SERVICE_SELECTIONS:
      return state.set("selectedServices", List());
    case MessagesActionTypes.CLEAR_CLIENT_SELECTIONS:
      return state.set("selectedClients", List());
    case MessagesActionTypes.SET_MESSAGES_LIST:
      return state.set("messagesList", fromJS(action.payload));
    case MessagesActionTypes.SET_MESSAGES_LIST_LOADING:
      return state.set("messagesListLoading", action.payload);
    case MessagesActionTypes.SET_FREQUENCY_TYPE:
      return state.set("frequencyType", action.payload);
    case MessagesActionTypes.SET_AUTOCOMPLETE_DATA:
      return state.withMutations((mutable) => {
        mutable
          .set("autocompleteServicesData", fromJS(action.payload.services))
          .set("autocompleteClientsData", fromJS(action.payload.clients));
      });
    case MessagesActionTypes.SET_RECEIVERS_AUTOCOMPLETE_DATA:
      return state.set(
        "autocompleteReceiversData",
        fromJS(action.payload.receiver)
      );
    case MessagesActionTypes.SET_MESSAGE_TEMPLATES:
      return state.set("messagesTemplates", action.payload);
    case MessagesActionTypes.SET_MESSAGE_ACTIVE_TEMPLATE:
      return state.set("messageActiveTemplate", action.payload);
    case MessagesActionTypes.SET_PAYMENT_DUE:
      return state.set("paymentDue", action.payload);
    case MessagesActionTypes.SET_PAYMENT_TYPE:
      return state.set("paymentType", action.payload);
    case MessagesActionTypes.SET_ACTIVE_MESSAGE_ID:
      return state.set("activeMessageId", action.payload);
    case MessagesActionTypes.SET_MESSAGE_DETAILS:
      return state.set("messageDetails", action.payload);
    case MessagesActionTypes.TRIGGER_RESET_LIST:
      return state.set("triggerResetList", !state.get("triggerResetList"));
    case MessagesActionTypes.SET_NEW_MESSAGE_DATA:
      return state.set("newMessageData", action.payload);
    case MessagesActionTypes.RESET_NEW_MESSAGE_DATA:
      return state.withMutations((mutator) =>
        mutator
          .set("newMessageData", initialNewMessageData)
          .set("attachmentsState", initialAttachmentsState)
      );
    case MessagesActionTypes.SET_ACTIVE_TAB:
      return state.set("activeTab", action.payload);
    case MessagesActionTypes.TOGGLE_MAIL_CONFIG:
      return state.set("isMailConfigShown", action.payload);
    case MessagesActionTypes.SET_MAIL_CONFIG:
      return state.set("mailConfig", action.payload);
    case MessagesActionTypes.SET_MESSAGES_LIST_FILTERS:
      return state.set("listFilters", action.payload);
    case MessagesActionTypes.SAVE_ATTACHMENTS_STATE:
      return state.set("attachmentsState", action.payload);
    case MessagesActionTypes.SET_RECIEVERS:
      return state.set("recieversList", action.payload.recieversList);
    default:
      return state;
  }
}
