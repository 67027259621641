import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { Container } from '@material-ui/core';
import Logo from 'src/assets/img/logo.png';
import FBIcon from '../../../assets/img/fb.png';
import YTIcon from '../../../assets/img/yt.png';
import goodpixIcon from '../../../assets/img/goodpix.png';
import styles from './Footer.module.scss';
import classNames from 'classnames';

const contactLink = {
  pathname: '/kontakt',
  key: Math.random(),
  state: {
    applied: true,
  },
};

const Footer = (props) => (
  <footer className={classNames(styles.Footer, props.className)} id="footer">
    <div className={styles.footer1}>
      <Container maxWidth="lg">
        <Grid container>
          {[1, 2, 3].map((v) => (
            <Grid item xs={12} sm={4} key={v}>
              <h3 className={styles.title}>Mapa serwisu</h3>
              <ul className={styles.links}>
                <li>
                  <Link to={contactLink}>Kontakt</Link>
                </li>
                <li>
                  <Link to="/klient-regulamin">Regulamin</Link>
                </li>
                <li>
                  <Link to="/polityka-prywatnosci">Prywatność</Link>
                </li>
              </ul>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>

    <div className={styles.footer2}>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={12} sm={3} md={3} className={styles.comainyInfo}>
            <img src={Logo} alt="Logo" className={styles.logo} />
            Competia Kancelaria Prawna sp. z o.o
            <br />
            ul. Ligocka 103, budynek nr 7<br />
            40-568 Katowice, Polska
            <br />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            NIP: 6342824440
            <br />
            KRS: 0000494519
            <br />
            REGON: 243474741
            <br />
            <br />
            Sąd Rejonowy dla m.st. Warszawy w Warszawie XII Wydział Gospodarczy KRS
          </Grid>

          <Grid item xs={12} sm={3} md={3} className={styles.footerRow}>
            <div className={styles.cont}>
              <h3 className={styles.title}>Mapa serwisu</h3>
              <ul className={styles.links}>
                <li>
                  <Link to={contactLink}>Kontakt</Link>
                </li>
                {/* <li>
                                    <a href={'/blog'}>Blog</a>
                                </li> */}
                <li>
                  <Link to="/klient-regulamin">Regulamin</Link>
                </li>
                <li>
                  <Link to="/polityka-prywatnosci">Prywatność</Link>
                </li>
              </ul>
            </div>
          </Grid>
          <Grid item xs={12} sm={2} md={2} className={styles.footerRow}>
            <div>
              <h3 className={styles.title}>Znajdź nas</h3>
              <div className={styles.social}>
                <a href="https://facebook.com/PoradyPrawnePrzezInternet" target="_blank">
                  <img src={FBIcon} alt="facebook icon" />
                </a>
                <a href="/">
                  <img src={YTIcon} alt="youtube icon" />
                </a>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>

    <div className={styles.footer3}>
      <Container maxWidth="lg">
        <div className={styles.left}>© {new Date().getFullYear()} Competia Kancelaria Prawna.</div>
        <div className={styles.right}>
          Projekt i wykonanie:
          <a href="https://goodpix.pl/">Agencja reklamowa GOODPIX</a>
          <a href="https://goodpix.pl/">
            <img src={goodpixIcon} alt="goodpix" />
          </a>
        </div>
      </Container>
    </div>
  </footer>
);

Footer.defaultProps = {};

export default Footer;
