export const OPEN_ATTACHMENT = "OPEN_ATTACHMENT";
export const CLOSE_ATTACHMENT = "CLOSE_ATTACHMENT";
export const SET_CURRENT_ATTACHMENT_INDEX = "SET_CURRENT_ATTACHMENT_INDEX";
export const UPDATE_ATTACHMENT_NUM_PAGES = "UPDATE_ATTACHMENT_NUM_PAGES";
export const CLOSE_ALL_ATTACHMENTS = "CLOSE_ALL_ATTACHMENTS";

export interface OpenAttachmentAction {
  type: typeof OPEN_ATTACHMENT;
  payload: any;
}

export interface CloseAttachmentAction {
  type: typeof CLOSE_ATTACHMENT;
  payload: string;
}

export interface SetCurrentAttachmentIndexAction {
  type: typeof SET_CURRENT_ATTACHMENT_INDEX;
  payload: number;
}

export interface UpdateAttachmentNumPagesAction {
  type: typeof UPDATE_ATTACHMENT_NUM_PAGES;
  payload: {
    attachmentId: string;
    numPages: number;
  };
}

export interface CloseAllAttachmentsAction {
  type: typeof CLOSE_ALL_ATTACHMENTS;
}

export type AttachmentActionTypes =
  | OpenAttachmentAction
  | CloseAttachmentAction
  | SetCurrentAttachmentIndexAction
  | UpdateAttachmentNumPagesAction
  | CloseAllAttachmentsAction;
