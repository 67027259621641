import * as React from "react";
import Select from "react-select";
import DatePicker from "react-date-picker";
import * as moment from "moment";
import {
  saveToServiceForm,
  setIsArrayTabInputValid,
  setServiceFormArrayData,
} from "src/actions/UpsertServiceForm";
import { connect } from "react-redux";
import { AppState } from "src/reducers";
import { RightNavBarOption } from "src/actions/RightNavBar";
import { debounce } from "lodash";
import { getCurrentRowData } from "src/selectors/UpsertServiceForm";
import { ISelectValue } from "src/components/Lawyer/Services/UpsertServiceForm/Common/PrioritySelectors";
import { withNamespaces, WithNamespaces } from "react-i18next";

interface IServiceStepRowProps extends WithNamespaces {
  selectedServiceTab: number;
  saveToServiceForm: (path: string[], value: any) => any;
  selectTypeOptions: any;
  selectVersionOptions: any;
  index: number;
  label: string;
  datePickerValue: string;
  selectTypeValue: ISelectValue;
  selectVersionValue: ISelectValue;
  inputValue: string;
  arrayIndex: number;
  addToPath: string;
  setServiceFormArrayData?: (
    path: string[],
    index: number,
    name: string,
    value: number | string | ISelectValue
  ) => void;
  position?: number;
  edit: boolean;
  removeChild: () => any;
  setIsArrayTabInputValid?: (
    path: string[],
    index: number,
    name: string,
    isValid: boolean
  ) => void;
  dateToRestrict: string;
  editable: number;
}

export enum SelectTypeEnum {
  "Notatka" = 1,
  "Etap",
}

export enum SelectVersionEnum {
  "Wypłata",
  "KL",
  "Rozprawa",
  "Ugoda",
  "Pismo",
  "Monit",
}

const ServiceStepRow = (props: IServiceStepRowProps) => {
  const [inputValue, changeInputValue] = React.useState(
    props.inputValue ? props.inputValue : ""
  );
  const selectedTab = RightNavBarOption[props.selectedServiceTab];

  const initialSelectTypeValue = {
    label: "Etap",
    value: 2,
  };

  const initialSelectVersionValue = {
    label: "Wypłata",
    value: 0,
  };

  React.useEffect(() => {
    if (!props.inputValue) {
      changeInputValue("");
    } else {
      changeInputValue(props.inputValue);
    }
  }, [props.inputValue]);

  React.useEffect(() => {
    props.setIsArrayTabInputValid!(
      [selectedTab, props.addToPath],
      props.arrayIndex,
      "isValid",
      !!(
        props.inputValue &&
        props.datePickerValue &&
        props.selectTypeValue &&
        props.selectVersionValue
      )
    );
  }, [
    props.inputValue,
    props.selectTypeValue,
    props.datePickerValue,
    props.selectVersionValue,
  ]);

  const saveToStore = (name: string, value: any) => {
    props.setServiceFormArrayData!(
      [selectedTab, props.addToPath],
      props.arrayIndex,
      name,
      value
    );
  };

  const debouncedSave = React.useCallback(debounce(saveToStore, 200), [
    props.arrayIndex,
    props.addToPath,
  ]);

  const onSelectTypeChange = (select: ISelectValue) => {
    saveToStore("type", select);
  };

  const onSelectVersionChange = (select: ISelectValue) => {
    saveToStore("version", select);
  };

  const onInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    changeInputValue(value);
    debouncedSave("description", value);
  };

  const onDateChange = (date: Date | Date[]) => {
    if (date && date instanceof Date) {
      saveToStore("created_at", moment(date).format("YYYY-MM-DD"));
    }
  };

  const onDeletionPress = () => {
    props.removeChild();
    props.setServiceFormArrayData!(
      [selectedTab, props.addToPath],
      props.arrayIndex!,
      "removed",
      1
    );
  };

  React.useEffect(() => {
    if (!props.selectTypeValue) {
      onSelectTypeChange(initialSelectTypeValue);
    }
    if (!props.selectVersionValue) {
      onSelectVersionChange(initialSelectVersionValue);
    }
    if (!props.datePickerValue) {
      props.setServiceFormArrayData!(
        [selectedTab, props.addToPath],
        props.arrayIndex,
        "created_at",
        moment().format()
      );
    }
  }, []);

  return (
    <div className="step__wrapper">
      <DatePicker
        disabled={props.position === 0 || props.edit || props.editable === 0}
        className="upsert-service-form__datepicker"
        onChange={onDateChange}
        value={
          props.datePickerValue
            ? moment.utc(props.datePickerValue).local().toDate()
            : undefined
        }
        minDate={
          props.dateToRestrict
            ? moment(props.dateToRestrict).toDate()
            : undefined
        }
        format="dd/MM/y"
      />
      <Select
        isDisabled={props.position === 0 || props.edit || props.editable === 0}
        className="select select_service"
        options={props.selectTypeOptions}
        onChange={onSelectTypeChange}
        value={props.selectTypeValue}
      />
      <Select
        isDisabled={props.position === 0 || props.edit || props.editable === 0}
        className="select select_service"
        options={props.selectVersionOptions}
        onChange={onSelectVersionChange}
        value={props.selectVersionValue}
      />
      <input
        disabled={props.position === 0 || props.edit || props.editable === 0}
        className="upsert-service-form__input input__field"
        placeholder={props.label}
        type="text"
        value={inputValue}
        onChange={onInputChange}
        autoComplete="off"
      />
      <button
        disabled={props.position === 0 || props.edit || props.editable === 0}
        onClick={onDeletionPress}
        className="upsert-service-form__button upsert-service-form__button--remove"
      >
        {props.t("app.common.upsertServiceForm.steps.removeButton")}
      </button>
    </div>
  );
};

const mapStateToProps = (state: AppState, props: any) => ({
  datePickerValue: getCurrentRowData(state, [
    RightNavBarOption[props.selectedServiceTab],
    props.addToPath,
    props.arrayIndex!,
    "created_at",
  ]),
  selectTypeValue: getCurrentRowData(state, [
    RightNavBarOption[props.selectedServiceTab],
    props.addToPath,
    props.arrayIndex!,
    "type",
  ]),
  selectVersionValue: getCurrentRowData(state, [
    RightNavBarOption[props.selectedServiceTab],
    props.addToPath,
    props.arrayIndex!,
    "version",
  ]),
  inputValue: getCurrentRowData(state, [
    RightNavBarOption[props.selectedServiceTab],
    props.addToPath,
    props.arrayIndex!,
    "description",
  ]),
  position: getCurrentRowData(state, [
    RightNavBarOption[props.selectedServiceTab],
    props.addToPath,
    props.arrayIndex!,
    "position",
  ]),
  editable: getCurrentRowData(state, [
    RightNavBarOption[props.selectedServiceTab],
    props.addToPath,
    props.arrayIndex!,
    "editable",
  ]),
});

const mapDispatchToProps = {
  saveToServiceForm,
  setServiceFormArrayData,
  setIsArrayTabInputValid,
};

export default withNamespaces()(
  connect(mapStateToProps, mapDispatchToProps)(ServiceStepRow)
);
