import { action, createAction } from 'typesafe-actions';

import { MainActionTypes } from 'src/action-types/Main';
import Api from 'src/services/Api';

export interface IPopUpStatus {
  value: boolean;
}

export const toggleChat = (value: boolean) =>
  action(MainActionTypes.TOGGLE_CHAT, value);

export const togglePayment = (value: boolean) =>
  action(MainActionTypes.TOGGLE_PAYMENT, value);

export const toggleValuation = (value: boolean) =>
  action(MainActionTypes.TOGGLE_VALUATION, value);

export const toggleDocumentFormatsList = (value: boolean) =>
  action(MainActionTypes.TOGGLE_DOCUMENT_FORMATS_LIST, value);

export const toggleDocumentList = (value: boolean) =>
  action(MainActionTypes.TOGGLE_DOCUMENTS, value);

export const toggleDocumentEdit = (value: boolean) =>
  action(MainActionTypes.TOGGLE_DOCUMENT_EDIT, value);

export const toggleAdditionalDocumentEdit = (value: boolean) =>
  action(MainActionTypes.TOGGLE_ADDITIONAL_DOCUMENT_EDIT, value);

export const toggleDocumentEditMinimalize = () =>
  action(MainActionTypes.TOGGLE_DOCUMENT_EDIT_MINIMALIZE);

export const toggleAdditionalDocumentEditMinimalize = () =>
  action(MainActionTypes.TOGGLE_ADDITIONAL_DOCUMENT_EDIT_MINIMALIZE);

export const toggleSecretaryChat = (value: boolean) =>
  action(MainActionTypes.TOGGLE_SECRETARY_CHAT, value);

export const toggleAddClientForm = (value: boolean) =>
  action(MainActionTypes.TOGGLE_ADD_CLIENT_FORM, value);

export const toggleAddServiceForm = (value: boolean) =>
  action(MainActionTypes.TOGGLE_ADD_SERVICE_FORM, value);

export const toggleCellPhone = (value: boolean) =>
  action(MainActionTypes.TOGGLE_CELL_PHONE, value);

export const toggleSwitchLawyerView = (value: boolean) =>
  action(MainActionTypes.TOGGLE_SWITCH_LAWYER_VIEW, value);

export const hideAdditionalComponents = () =>
  action(MainActionTypes.HIDE_ADDITIONAL_COMPONENTS);

export const clearData = createAction(MainActionTypes.CLEAR_DATA);

export const setCurrentListLength = (value: number) => {
  action(MainActionTypes.SET_CURRENT_LIST_LENGTH, value);
};

export const setNotification = (value: any) => (dispatch) => {
  dispatch({ type: MainActionTypes.SET_NOTIFICATION, payload: value });
};

export const setUserId = (value: number) =>
  action(MainActionTypes.SET_USER_ID, value);

export const setUser = (user: any) => async (dispatch) => {
  if (user.clientId) {
    const clientResponse = await Api.get('/clients/' + user.clientId);
    if (clientResponse.data.success) {
      user.details = clientResponse.data.data;
    }
  }

  if (user.lawyerId) {
    const lawyerResponse = await Api.get('/lawyers/' + user.lawyerId);
    if (lawyerResponse.data.success) {
      user.details = lawyerResponse.data.data;
    }
  }

  if (user.dealerId) {
    const lawyerResponse = await Api.get('/dealers/' + user.dealerId);
    if (lawyerResponse.data.success) {
      user.details = lawyerResponse.data.data;
    }
  }

  if (user.secretaryId) {
    // const secretaryResponse =  await Api.get('/secretary/' + user.secretaryId);
    // if(secretaryResponse.data.success){
    //     user.details = secretaryResponse.data.data;
    // }
    user.details = { id: user.secretaryId };
  }

  dispatch({ type: MainActionTypes.SET_USER, payload: user });
};

export const toggleBlocked = (value: boolean) =>
  action(MainActionTypes.TOGGLE_BLOCKED, value);
export const setCurrentPage = (tableName: string, value: number) =>
  action(MainActionTypes.SET_CURRENT_PAGE, { tableName, value });

export const toggleShowCurrentUserServices = (value: boolean) =>
  action(MainActionTypes.SET_SHOW_CURRENT_USER_SERVICES, value);

export const toggleShowCurrentUserCalls = (value: boolean) =>
  action(MainActionTypes.SET_SHOW_CURRENT_USER_CALLS, value);

export const toggleShowCurrentUserMessages = (value: boolean) =>
  action(MainActionTypes.SET_SHOW_CURRENT_USER_MESSAGES, value);
