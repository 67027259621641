import { connectRouter } from "connected-react-router/immutable";
import { History } from "history";
import { Map } from "immutable";
import { reducer as notifications } from "react-notification-system-redux";
import { combineReducers } from "redux-immutable";

import webSocket, { WebSocketState } from "src/reducers/WebSocket";
import rightNavBar, { RightNavBarState } from "src/reducers/RightNavBar";
import upsertServiceForm, {
  UpsertServiceFormState,
} from "src/reducers/UpsertServiceForm";
import modal, { ModalState } from "./Modal";
import filters, { FiltersState } from "./Filters";
import serviceTypes, { ServiceTypeState } from "./ServiceTypes";
import services, { ServicesState } from "./Services";
import clients from "./clients";
import dealerClients from "./Dealer/clients";
import customers from "./customers";
import domains from "./domains";
import messages from "./Messages/index";
import domainTypes from "./domianTypes";
import dealerPositions from "./dealerPositions";
import lawyerAppointments from "./LawyerAppointments";
import lawyers from "./lawyers";
import analyzes from "./analyzes";
import registerItemTypeKind from "./registerItemTypeKind";
import attachmentsReducer, { AttachmentsState } from "../reducers/Attachments";

import main, { MainState } from "./Main";

interface IRootReducer {
  modal: ModalState;
  services: ServicesState;
  serviceTypes: ServiceTypeState;
  filters: FiltersState;
  webSocket: WebSocketState;
  rightNavBar: RightNavBarState;
  upsertServiceForm: UpsertServiceFormState;
  main: MainState;
  dealerClients: any;
  lawyers: any;
  analyzes: any;
  registerItemTypeKind: any;
  attachments: AttachmentsState;
}

export type AppState = Map<
  keyof IRootReducer,
  IRootReducer[keyof IRootReducer]
>;

const rootReducers = (history: History) =>
  combineReducers({
    modal,
    services,
    webSocket,
    rightNavBar,
    upsertServiceForm,
    serviceTypes,
    filters,
    clients,
    customers,
    domains,
    domainTypes,
    dealerPositions,
    main,
    lawyerAppointments,
    messages,
    dealerClients,
    lawyers,
    analyzes,
    registerItemTypeKind,
    attachments: attachmentsReducer,
  });

export default rootReducers;
