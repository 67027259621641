export interface ClientAppointment {
  id: string;
  start: string;
  end: string;
  topic: string;
  comments: string;
  status: AppointmentStatus;
  isFree: boolean;
  sessionId: string;
  lawyerId: number;
  domain: string;
  lawyer: string;
  serviceNumber: string;
}

export interface ClientService {
  id: string;
  title: string;
  number: string;
  status: ServiceStatus;
  lawyerId: number;
  domain: string;
  sessionId: string;
  stages: ClientServiceStage[];
}

export interface ClientServiceStage {
  description: string;
  date: string;
}

export interface Lawyer {
  id: number;
  name: string;
  surname: string;
  licenceNumber: string;
  description: string;
  education: string;
  userId: number;
  imageId: number;
  lawyerStatus: LawyerStatus;
  isVerified: boolean;
  isActive: boolean;
  domains: LawyerDomain[];
}

export interface DomainBase {
  id: number;
  name: string;
}

export interface LawyerDomain extends DomainBase {
  experience: string;
  isMain: boolean;
}

export interface Domain extends DomainBase {
  keywords: string;
  description: string;
  imageId: string;
  imageLocation: string;
}

export enum LawyerStatus {
  LAWYER = "Prawnik",
  LEGAL_CONSUEL = "Radca prawny",
  ADVOCATE = "Adwokat",
}

export enum UserRole {
  Customer = "customer",
  Lawyer = "lawyer",
  Secretary = "secretary",
  Dealer = "dealer",
  Admin = "admin",
}

export enum ServiceStatus {
  OPEN = "Otwarte",
  CLOSED = "Zamknięte",
}

export enum ServiceItemType {
  Monit = 1,
  Pismo,
  Rozprawa,
  Dokumenty,
  Ugoda,
  Termin,
  Platnosc,
  Konsultacja,
  Inne,
  Zakonczone,
}

export enum ServiceItemTypeKind {
  Monit_Monitoring = 1001,
  Monit_Opis = 1101,
  Pismo_Wezwanie_Do_Zaplaty = 2001,
  Pismo_Odpowiedz_Na_Wezwanie = 2002,
  Pismo_Wyslanie_Reklamacji = 2003,
  Pismo_Odpowiedz_Na_Reklamacje = 2004,
  Pismo_Wyslano_Wniosek_Do_Banku = 2005,
  Pismo_Formulowanie_Pozwu = 2006,
  Pismo_Przygotowano_Pozew = 2007,
  Pismo_Wyslano_Pozew = 2008,
  Pismo_SO_Katowice = 2100,
  Rozprawa_Odbyta = 3001,
  Rozprawa_Odwolana = 3002,
  Rozprawa_Opis = 3100,
  Dokumenty_Otrzymano = 4001,
  Dokumenty_Wykonano_Analize = 4002,
  Dokumenty_Wykonano_Analize_Uzupelniajaca = 4003,
  Dokumenty_Otrzymano_Z_Banku = 4004,
  Dokumenty_Przekazano_Do_Bieglego = 4005,
  Dokumenty_Otrzymano_Opinie_Bieglego = 4006,
  Dokumenty_Prosba_Uzupelnienia_Danych = 4007,
  Dokumenty_Otrzymano_Brakujace_Bieglego = 4008,
  Dokumenty_Opis = 4100,
  Ugoda_Zawarcie = 5001,
  Ugoda_Zostala_Zawarta = 5002,
  Ugoda_Negocjacje = 5003,
  Ugoda_Opis = 5100,
  Termin_Rozprawa = 6001,
  Termin_Publikacja_Wyroku = 6002,
  Termin_Opis = 6100,
  Platnosc_Oplacono_FV = 7001,
  Platnosc_Oplacono_FV1 = 7002,
  Platnosc_Oplacono_FV2 = 7003,
  Platnosc_Oplacono_FV3 = 7004,
  Platnosc_Oplacono_FV4 = 7005,
  Platnosc_Oplacono_FV5 = 7006,
  Platnosc_Opis = 7007,
  Konsultacja_Wideokonsultacja = 8001,
  Konsultacja_Telefoniczna = 8002,
  Konsultacja_Prawna = 8003,
  Konsultacja_Opis = 8100,
  Inne_Otwarcie_Zlecenia = 9001,
  Inne_Wyslano_Dokumenty_Na_Obsluge = 9002,
  Inne_Opis = 9100,
  Zakonczone_Opis = 10100,
}

export enum ServiceItemOtherType {
  Rozmowy = 1,
  Ugoda,
  Konkurencja,
  Rezygnacja,
}

export const ServiceItemTypeIdByString = {
  Monit: 1,
  Pismo: 2,
  Rozprawa: 3,
  Dokumenty: 4,
  Ugoda: 5,
  Termin: 6,
  Płatność: 7,
  Konsultacja: 8,
  Inne: 9,
  "Zlecenie zakończone": 10,
  Rozmowy: 13,
  Konkurencja: 14,
  Rezygnacja: 15,
};

export const ServiceItemTypeKindIdByString = {
  'Monitoring postępowania cywilnego': 1001,
  'Monit Opis': 1101,
  'Wysłano do banku wezwanie do zapłaty': 2001,
  'Otrzymano odpowiedź na wezwanie do zapłaty.': 2002,
  'Wysłano do banku reklamację.': 2003,
  'Otrzymano odpowiedź na reklamację.': 2004,
  'Wysłano Klientowi wniosek do banku, oczekujemy na dokumenty.': 2005,
  'Trwa formułowanie pozwu.': 2006,
  'Przygotowano Pozew.': 2007,
  'Wysłano do sądu Pozew.': 2008,
  '[ SO Katowice ] Otrzymano postanowienie o udzieleniu zabezpieczenia z dnia': 2100,
  'Rozprawa odbyta': 3001,
  'Rozprawa odwołana': 3002,
  'Rozprawa Opis': 3100,
  'Otrzymano od Klienta dokumenty do analizy.': 4001,
  'Wykonano analizę': 4002,
  'Wykonano analizę uzupełniającą.': 4003,
  'Otrzymano od Klienta dokumenty z banku.': 4004,
  'Dokumenty z banku przekazano do biegłego w celu sporządzenia opinii.': 4005,
  'Otrzymano opinię biegłego.': 4006,
  'Prośba do Klienta o uzupełnienie brakującej dokumentacji.': 4007,
  'Otrzymano od Klienta brakujące dokumenty.': 4008,
  'Dokumenty Opis': 4100,
  'Zawarcie ugody': 5001,
  'Ugoda została zawarta': 5002,
  'Negocjacje': 5003,
  'Ugoda Opis': 5100,
  'Rozprawa [data] [godzina] [nr sali/zdalna]': 6001,
  'Publikacja wyroku ': 6002,
  'Termin Opis': 6100,
  'Klient opłacił FV.': 7001,
  'Klient opłacił FV - 1rata.': 7002,
  'Klient opłacił FV - 2rata.': 7003,
  'Klient opłacił FV - 3rata.': 7004,
  'Klient opłacił FV - 4rata.': 7005,
  'Klient opłacił FV - 5rata.': 7006,
  'Platność Opis': 7007,
  'Wideokonsultacja  prawna': 8001,
  'Telefoniczna konsultacja prawna': 8002,
  'Konsultacja prawna w kancelarii': 8003,
  'Konsultacja Opis': 8100,
  'Otwarcie zlecenia': 9001,
  'Dokumenty na obsługę prawną wysłano do Klienta.': 9002,
  'Inne Opis': 9100,
  'Zlecenie zakończone Opis': 10100,
};

export enum ServiceItemMode {
  Etap = 1,
  Notatka,
}

export enum AppointmentStatus {
  PLANNED = "Planned",
  PAYMENT_PENDING = "Payment pending",
  ENDED = "Ended",
}

export enum RightNavBarOption {
  actions = 0,
  person,
  description,
  enemy,
  offices,
  consequences,
  condition,
  witnesses,
  additional,
  documentation,
  // agreement,
  // guardian
}

export interface IRightNavBarStatus {
  value: boolean;
}
export interface IRightNavBarOption {
  option: RightNavBarOption;
}
