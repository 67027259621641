import { chain } from "lodash";
import { IServiceDetails } from "src/reducers/Services";
// import { IServiceListElement } from 'lawpoint-common/typing/staff-panel';
import { getSelectorsValues } from "src/transformators/UpsertServiceForm";

export const setServiceDetails = (serviceDetails: IServiceDetails) => {
  serviceDetails.people = chain(serviceDetails.people)
    .sortBy(["record_type", "position"])
    .value();
  serviceDetails.steps = chain(serviceDetails.steps)
    .orderBy("position", "desc")
    .value();

  return serviceDetails;
};

export const parseServiceList = (list: Array<any>) => {
  list.map((serviceItem: /*IServiceListElement*/ any) => {
    const selectors = {
      select1: serviceItem.select1,
      select2: serviceItem.select2,
      select3: serviceItem.select3,
    };

    const parsedSelectors = {
      select1: getSelectorsValues(
        selectors,
        "select_1",
        undefined,
        serviceItem.branch_id
      ),
      select2: getSelectorsValues(
        selectors,
        "select_2",
        undefined,
        serviceItem.branch_id
      ),
      select3: getSelectorsValues(
        selectors,
        "select_3",
        undefined,
        serviceItem.branch_id
      ),
    };
    return {
      ...serviceItem,
      select1: parsedSelectors.select1 ? parsedSelectors.select1.label : null,
      select2: parsedSelectors.select2 ? parsedSelectors.select2.label : null,
      select3: parsedSelectors.select3 ? parsedSelectors.select3.label : null,
    };
  });
};
