import {
  SET_CUSTOMER_APPOINTMENTS,
  SET_CUSTOMER_APPOINTMENT,
  SET_CUSTOMER_APPOINTMENTS_LOADING,
  SET_CUSTOMER_APPOINTMENTS_LAWYER,
  SET_CUSTOMER_APPOINTMENTS_ID,
  SET_CUSTOMER_APPOINTMENT_CALL,
  SET_CUSTOMER_SERVICE,
  SET_CUSTOMER_SERVICES,
  SET_CUSTOMER_SERVICE_ID,
  SET_CUSTOMER_SERVICE_OBJ,
  SET_CUSTOMER_MESSAGES,
  SET_CUSTOMER_APPOINTMENT_MESSAGES,
  SET_CUSTOMER_MESSAGE,
  SET_CUSTOMER_MESSAGE_ID,
  SET_CUSTOMER_AUTOCOMPLETE_LAWYERS,
  SET_CUSTOMER_AUTOCOMPLETE_SERVICES,
} from "../actions/types";

const initialState = {
  callsHistory: [],
  callsHistoryCount: 0,
  callsHistoryLoading: false,
  activeCallId: null,
  activeCallObj: null,
  activeMessageId: null,
  appointment: null,
  appointmentService: null,
  appointmentLawyer: null,
  appointmentMessages: [],
  appointmentLawyerServices: [],
  activeServiceId: null,
  messages: [],
  serviceMessages: [],
  message: null,
  layer: null,
  service: null,
  lastStage: null,
  wycena: null,
  completed: null,
  autocompleteServices: [],
  autocompleteLawyers: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CUSTOMER_APPOINTMENTS: {
      const { callsHistory } = action.payload;
      return {
        ...state,
        callsHistory: callsHistory,
        callsHistoryCount: callsHistory.length,
      };
    }
    case SET_CUSTOMER_APPOINTMENTS_LOADING: {
      return {
        ...state,
        callsHistoryLoading: action.payload,
      };
    }
    case SET_CUSTOMER_APPOINTMENTS_LAWYER: {
      const { appointmentLawyer } = action.payload;
      return {
        ...state,
        appointmentLawyer: appointmentLawyer,
      };
    }
    case SET_CUSTOMER_APPOINTMENT_MESSAGES: {
      const { appointmentMessages } = action.payload;
      return {
        ...state,
        appointmentMessages: appointmentMessages,
      };
    }
    case SET_CUSTOMER_MESSAGE: {
      const { message, lawyer, service, lastStage } = action.payload;
      return {
        ...state,
        message: message,
        lawyer: lawyer,
        service: service,
        lastStage: lastStage,
      };
    }

    case SET_CUSTOMER_APPOINTMENTS_ID: {
      return {
        ...state,
        activeCallId: action.payload,
      };
    }

    case SET_CUSTOMER_APPOINTMENT: {
      const {
        appointment,
        appointmentService,
        appointmentLawyer,
        appointmentMessages,
      } = action.payload;
      return {
        ...state,
        appointment: appointment,
        appointmentService: appointmentService,
        appointmentLawyer: appointmentLawyer,
        appointmentMessages: appointmentMessages,
      };
    }

    case SET_CUSTOMER_APPOINTMENT_CALL: {
      return {
        ...state,
        activeCallObj: action.payload,
      };
    }
    case SET_CUSTOMER_SERVICE: {
      const {
        activeServiceObj,
        serviceMessages,
        appointmentLawyer,
        serviceAppointment,
        lastStage,
        wycena,
        completed,
      } = action.payload;
      return {
        ...state,
        activeServiceObj: activeServiceObj,
        activeServiceId: activeServiceObj.id,
        serviceMessages: serviceMessages,
        appointmentLawyer: appointmentLawyer,
        serviceAppointment: serviceAppointment,
        lastStage: lastStage,
        wycena: wycena,
        completed: completed,
      };
    }
    case SET_CUSTOMER_SERVICES: {
      const { serviceHistory } = action.payload;
      return {
        ...state,
        serviceHistory: serviceHistory,
      };
    }
    case SET_CUSTOMER_SERVICE_ID: {
      return {
        ...state,
        activeServiceId: action.payload,
      };
    }

    case SET_CUSTOMER_SERVICE_OBJ: {
      return {
        ...state,
        activeServiceObj: action.payload,
      };
    }

    case SET_CUSTOMER_AUTOCOMPLETE_LAWYERS: {
      return {
        ...state,
        autocompleteLawyers: action.payload.autocompleteLawyers,
      };
    }

    case SET_CUSTOMER_AUTOCOMPLETE_SERVICES: {
      return {
        ...state,
        autocompleteServices: action.payload.autocompleteServices,
      };
    }

    case SET_CUSTOMER_MESSAGE_ID: {
      return {
        ...state,
        activeMessageId: action.payload,
      };
    }

    case SET_CUSTOMER_MESSAGES: {
      const { messagesRecived, messagesSent, messages, messagesTrash } =
        action.payload;
      return {
        ...state,
        messages: messages,
        messagesRecived: messagesRecived,
        messagesSent: messagesSent,
        messagesTrash: messagesTrash,
      };
    }

    default:
      return state;
  }
}
