export enum UpsertServiceFormActionTypes {
  SET_FORM_DATA = "UPSERT_SERVICE_FORM/SET_DATA",
  SET_CLIENT = "UPSERT_SERVICE_FORM/SET_CLIENT",
  SET_GUARDIAN = "UPSERT_SERVICE_FORM/SET_GUARDIAN",
  SEND_FORM_DATA = "UPSERT_SERVICE_FORM/SEND_FORM_DATA",
  CLEAR_FORM_DATA = "UPSERT_SERVICE_FORM/CLEAR_FORM_DATA",
  SET_FORM_DATA_STATUS = "UPSERT_SERVICE_FORM/SET_FORM_DATA_STATUS",
  SET_SERVICE_FORM_DATA = "UPSERT_SERVICE_FORM/SET_FORM_DATA",
  SET_SELECTED_CATEGORY = "UPSERT_SERVICE_FORM/SELECTED_CATEGORY_SET",
  SERVICE_FORM_DATA_SET = "UPSERT_SERVICE_FORM/SERVICE_FORM_DATA_SET",
  SET_PRIORITY_SELECTORS_DATA = "UPSERT_SERVICE_FORM/PRIORITY_SELECTORS_DATA_SET",
  SET_FORM_ARRAY_DATA = "UPSERT_SERVICE_FORM/FORM_ARRAY_DATA_SET",
  SET_FORM_SAVED_DATA = "UPSERT_SERVICE_FORM/FORM_SAVED_DATA_SET",
  SET_FORM_VALIDATION_DATA = "UPSERT_SERVICE_FORM/FROM_VALIDATION_DATA_SET",
  SET_FORM_TAB_VALIDATION_DATA = "UPSERT_SERVICE_FORM/FORM_TAB_VALIDATION_DATA_SET",
  REVERT_CHANGES = "UPSERT_SERVICE_FORM/CHANGES_REVERT",
  SET_AUTOCOMPLETE_SUGGESTIONS = "UPSERT_SERVICE_FORM/AUTOCOMPLETE_SUGGESTIONS_SET",
  AUTOCOMPLETE_PERSON_DATA = "UPSERT_SERVICE_FORM/PERSON_DATA_AUTOCOMPLETE",
  SET_SELECTED_TYPE = "SET_SELECTED_TYPE",
  SET_SELECTED_DEALER_POSITION = "SET_SELECTED_DEALER_POSITION",
}
