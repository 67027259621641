import { fromJS, List } from "immutable";
import {
  AttachmentActionTypes,
  CLOSE_ALL_ATTACHMENTS,
  UPDATE_ATTACHMENT_NUM_PAGES,
} from "../../action-types/Attachments";
import {
  OPEN_ATTACHMENT,
  CLOSE_ATTACHMENT,
  SET_CURRENT_ATTACHMENT_INDEX,
} from "../../action-types/Attachments";

import { ImmutableMap } from "src/helpers/ImmutableHelpers";

export interface AttachmentsState extends ImmutableMap<any> {
  openAttachments: List<any>;
  currentAttachmentIndex: number;
}

const initialState: AttachmentsState = fromJS({
  openAttachments: [],
  currentAttachmentIndex: -1,
});

const attachmentsReducer = (
  state = initialState,
  action: AttachmentActionTypes
): AttachmentsState => {
  switch (action.type) {
    case OPEN_ATTACHMENT: {
      const attachment = action.payload;
      console.log("Reducer - OPEN_ATTACHMENT:", attachment);
      const openAttachments = state.get("openAttachments").toJS();
      const isAlreadyOpen = openAttachments.some(
        (att: any) => att.id === attachment.id
      );

      let updatedAttachments = openAttachments;
      let currentAttachmentIndex = state.get("currentAttachmentIndex");

      if (!isAlreadyOpen) {
        updatedAttachments = [...openAttachments, attachment];
        currentAttachmentIndex = updatedAttachments.length - 1;
      } else {
        currentAttachmentIndex = openAttachments.findIndex(
          (att: any) => att.id === attachment.id
        );
      }

      return state
        .set("openAttachments", fromJS(updatedAttachments))
        .set("currentAttachmentIndex", currentAttachmentIndex);
    }

    case CLOSE_ATTACHMENT: {
      const attachmentId = action.payload;
      const openAttachments = state.get("openAttachments").toJS();

      const updatedAttachments = openAttachments.filter(
        (att: any) => att.id !== attachmentId
      );

      let currentAttachmentIndex = state.get("currentAttachmentIndex");

      if (updatedAttachments.length === 0) {
        currentAttachmentIndex = -1;
      } else if (currentAttachmentIndex >= updatedAttachments.length) {
        currentAttachmentIndex = updatedAttachments.length - 1;
      }

      return state
        .set("openAttachments", fromJS(updatedAttachments))
        .set("currentAttachmentIndex", currentAttachmentIndex);
    }

    case SET_CURRENT_ATTACHMENT_INDEX:
      return state.set("currentAttachmentIndex", action.payload);

    default:
      return state;

    case UPDATE_ATTACHMENT_NUM_PAGES: {
      const { attachmentId, numPages } = action.payload;
      const openAttachments = state.get("openAttachments").toJS();

      const updatedAttachments = openAttachments.map((att: any) => {
        if ((att.id || att.location) === attachmentId) {
          return { ...att, numPages };
        }
        return att;
      });

      return state.set("openAttachments", fromJS(updatedAttachments));
    }

    case CLOSE_ALL_ATTACHMENTS: {
      return state
        .set("openAttachments", fromJS([]))
        .set("currentAttachmentIndex", -1);
    }
  }
};

export default attachmentsReducer;
