import { SET_REGISTER_ITEM_TYPE_KIND } from "../actions/types";

const initialState = {
  registerItemTypeKind: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_REGISTER_ITEM_TYPE_KIND: {
      console.log("KINDS",action.payload)
      return {
        ...state,
        registerItemTypeKind: action.payload,
      };
    }
    default:
      return state;
  }
}
