//Added dealerPositions reducer - JG

import { SET_DEALER_POSITIONS } from "../actions/types";

const initialState = {
  dealerPositionsList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_DEALER_POSITIONS: {
      return {
        ...state,
        dealerPositionsList: action.payload,
      };
    }
    default:
      return state;
  }
}
