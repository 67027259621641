import { Map, Record } from 'immutable';
import { uniqueId, cloneDeep } from 'lodash';

import { ImmutableMap } from 'src/helpers/ImmutableHelpers';
import { hasEmptyKeys, changeNestedNullToEmptyString } from 'src/helpers/common';

import {
  IServiceUser,
  IEditServiceFormDetails,
  IUpsertServiceFormState,
  RecordType,
  newUserObject,
  UpsertServiceFormState,
  newDetailObject,
} from 'src/reducers/UpsertServiceForm';
import { SelectTypeEnum, SelectVersionEnum } from 'src/components/Lawyer/Services/UpsertServiceForm/DynamicServiceForm/ServiceStepRow';
import { RightNavBarOption } from 'src/actions/RightNavBar';
import { getReferences } from 'src/components/Lawyer/Services/UpsertServiceForm/Common/serviceFormObject';
import { ServiceItemMode, ServiceItemTypeIdByString, ServiceItemTypeKindIdByString } from 'src/types/types';

const moment = require('moment');
const filterValuesFromPeopleList = (data: ImmutableMap<IEditServiceFormDetails>, recordType: RecordType) => {
  const cloned = cloneDeep(data);

  return cloned.get('cards').filter((el: ImmutableMap<IServiceUser>) => el.get('side') === recordType);
};

export const setServiceFormData = (state: Record<IUpsertServiceFormState>, serviceData: ImmutableMap<IEditServiceFormDetails>) => {
  serviceData = changeNestedNullToEmptyString(serviceData);

  return state.withMutations((mutable) => {
    let steps = serviceData.get('stages');
    let notes = serviceData.get('notes');

    steps = steps.map((step: ImmutableMap<any>) => {
      const type = step.get('type');
      let kind = step.get('kind');
      let normalizedKind = kind ? kind.trim() : ''; 
    
      const registerItemTypeId = ServiceItemTypeIdByString[type];
    
      let combinedKey = normalizedKind;
      if (normalizedKind === 'Opis') {
        combinedKey = `${type} ${normalizedKind}`;
      }
    
      let registerItemTypeKindId = ServiceItemTypeKindIdByString[combinedKey];
    
      if (registerItemTypeKindId === undefined) {
        registerItemTypeKindId = 9100;
        normalizedKind = 'Opis';
        combinedKey = `${type} ${normalizedKind}`;
        kind = 'Opis';
      }
    
      // console.log('Register Item Type:', type);
      // console.log('Mapped Item Type Id:', registerItemTypeId);
      // console.log('Register Item Kind:', kind);
      // console.log('Normalized Kind:', normalizedKind);
      // console.log('Combined Key:', combinedKey);
      // console.log('Mapped Item Kind Id:', registerItemTypeKindId);
      // console.log('----------------------------------------------------------------');
    
      let updatedStep = step
        .set('created_at', moment(step.get('created_at')).toDate())
        .set('registerItemModeId', ServiceItemMode.Etap)
        .set('registerItemTypeId', registerItemTypeId)
        .set('registerItemTypeKindId', registerItemTypeKindId)
        .set('kind', kind);
    
      return updatedStep;
    });
    
    notes = notes.map((note: ImmutableMap<any>) => {
      const type = note.get('type');
      let kind = note.get('kind');
      let normalizedType = type.trim();
      let normalizedKind = kind ? kind.trim() : '';
    
      const registerItemTypeId = ServiceItemTypeIdByString[normalizedType];
      let registerItemTypeKindId = ServiceItemTypeKindIdByString[normalizedKind];
    
      if (registerItemTypeKindId === undefined) {
        registerItemTypeKindId = 9100;
        normalizedKind = 'Opis';
        kind = 'Opis';
      }
    
      // console.log('Register Item Type:', type);
      // console.log('Mapped Item Type Id:', registerItemTypeId);
      // console.log('Register Item Kind:', kind);
      // console.log('Normalized Kind:', normalizedKind);
      // console.log('Mapped Item Kind Id:', registerItemTypeKindId);
      // console.log('----------------------------------------------------------------');
    
      let updatedNote = note
        .set('created_at', moment(note.get('created_at')).toDate())
        .set('registerItemModeId', ServiceItemMode.Notatka)
        .set('registerItemTypeId', registerItemTypeId)
        .set('registerItemTypeKindId', registerItemTypeKindId);
    
      return updatedNote;
    });
    
    steps = steps
      .merge(notes)
      .sort((stepA: ImmutableMap<any>, stepB: ImmutableMap<any>) => (stepA.get('date') > stepB.get('date') ? 1 : -1));

    const details = serviceData.get('details') ? serviceData.get('details') : newDetailObject;

    const subject = filterValuesFromPeopleList(details, 0) ? filterValuesFromPeopleList(details, 0).get(0) : null;
    const description: ImmutableMap<any> = Map({
      description: details.get('description'),
      domain_id: serviceData.getIn(['domain', 'id']),
      domain_type_id: serviceData.getIn(['domainType', 'id']),
      loanAnalysis: details.get('loanAnalysis'),
    });

    const opponents = filterValuesFromPeopleList(details, 1);
    const offices = filterValuesFromPeopleList(details, 2);
    const witnesses = filterValuesFromPeopleList(details, 3);

    mutable
      .set('steps', steps)
      .set('subject', subject || newUserObject(uniqueId(), 0))
      .set('details', details)
      .set('description', description)
      .set(
        'opponents',
        opponents.map((el: any) => el.set('key', uniqueId()))
      )
      .set(
        'offices',
        offices.map((el: any) => el.set('key', uniqueId()))
      )
      .setIn(['consequence', 'consequences'], details.get('stateAfter'))
      .setIn(['condition', 'before_state'], details.get('stateBefore'))
      .set(
        'witnesses',
        witnesses.map((el: any) => el.set('key', uniqueId()))
      )
      .setIn(['additionalInformation', 'additional_information'], details.get('extraInformation'))
      .set('guardianId', details.get('guardian_id'))
      .set('serviceId', serviceData.get('id'));

    mutable
      .setIn(['data_saved', 'steps'], steps && steps.size > 0)
      .setIn(['data_saved', 'subject'], subject && subject.size > 0)
      .setIn(['data_saved', 'description'], !hasEmptyKeys(description))
      .setIn(['data_saved', 'opponents'], opponents && opponents.size > 0)
      .setIn(['data_saved', 'offices'], offices && offices.size > 0)
      .setIn(['data_saved', 'consequence'], !!details.get('stateAfter'))
      .setIn(['data_saved', 'condition'], !!details.get('stateBefore'))
      .setIn(['data_saved', 'witnesses'], witnesses && witnesses.size > 0)
      .setIn(['data_saved', 'additionalInformation'], !!details.get('extraInformation'))
      .setIn(['data_saved', 'guardian'], !!serviceData.get('guardian_id'));
  });
};

const handleContacts = (state: UpsertServiceFormState, path: string[], value: string | number, name: string, firstInPath: string) => {
  const [parent, child, i, contacts] = path;
  const hasExistingParent = state.getIn([firstInPath, parent, child, i]);
  if (hasExistingParent) {
    return state.setIn([firstInPath, parent, child, i, contacts], [{ [name]: value }]);
  } else {
    const existingPeople = state.getIn([firstInPath, parent, child]);
    if (existingPeople) {
      return state.setIn([firstInPath, parent, child], [...existingPeople, { contacts: [{ [name]: value }] }]);
    } else {
      return state.setIn([firstInPath, parent, child], [{ contacts: [{ [name]: value }] }]);
    }
  }
};

const handleCurrent = (current: any, index: number, name: string, value: string | number) => {
  if (current[index]) {
    return current.map((el: any, i: number) => (i === index ? { ...current[index], [name]: value } : el));
  } else {
    return [...current, { [name]: value }];
  }
};

export const handleArrayData = (state: UpsertServiceFormState, { index, name, value, path }: any, firstInPath: string) => {
  const current = state.getIn([firstInPath, ...path]);
  if (current) {
    return state.setIn([firstInPath, ...path], handleCurrent(current, index, name, value));
  } else {
    return path.length === 4
      ? handleContacts(state, path, value, name, firstInPath)
      : state.setIn([firstInPath, ...path], [{ [name]: value }]);
  }
};

export interface IStep {
  id: number;
  type: number;
  version: number;
  position: number;
  created_at: string;
  next_action_date: string | null;
  admin_id: number;
  description: string;
  service_id: number;
}

export const getSelectorsValues = (selectorValues: any, select: string, state?: UpsertServiceFormState, branchId?: number) => {
  const { select1, select2, select3 } = selectorValues;
  let branch;
  if (branchId) {
    branch = branchId;
  }
  if (state) {
    branch = state.get('selectedBranch').get('value');
  }
  if (!branch || !select1 || !select2 || !select3) {
    return;
  }
  const object = getReferences();
  if (select === 'select_1') {
    return object[select][branch][selectorValues.select1];
  } else if (select === 'select_2') {
    return object[select][select1][select2];
  } else {
    return object[select][select1][select3];
  }
};

const tabsAsObject = ['subject', 'relations', 'before_state', 'consequences', 'additional_information_tab'];

export const resultOutput = ({ steps, people, matter }: any) => {
  const newSteps = steps
    .map((step: IStep) => ({
      ...step,
      type: {
        value: step.type,
        label: SelectTypeEnum[step.type],
      },
      version: {
        value: step.version,
        label: SelectVersionEnum[step.version],
      },
    }))
    .sort((step1: IStep, step2: IStep) => (step1.position > step2.position ? 1 : -1));
  const newMatter = {
    ...matter,
    date: matter.date ? moment.utc(matter.date).local().format('DD/MM/YYYY') : null,
    hour: matter.date ? moment.utc(matter.date).local().format('HH:mm') : null,
  };
  const result = {
    actions: { row: newSteps },
    description: { matter: newMatter },
  };
  const assignPerson = (person: any) => {
    const { field_name, record_type } = person;
    if (typeof result[RightNavBarOption[record_type]] === 'undefined') {
      result[RightNavBarOption[record_type]] = {};
    }
    if (typeof result[RightNavBarOption[record_type]][field_name] === 'undefined') {
      result[RightNavBarOption[record_type]][field_name] = [...tabsAsObject].includes(field_name) ? {} : [];
    }

    const keyToModify = Object.keys(person).filter((item) => item.includes('date'));

    const newPerson = {
      ...person,
    };

    keyToModify.forEach((key: string) => {
      if (newPerson[key]) {
        const valid = moment(newPerson[key]).isValid();
        newPerson[key] = valid ? moment.utc(newPerson[key]).local().format('DD/MM/YYYY') : null;
      }
    });

    if (result[RightNavBarOption[record_type]][field_name] instanceof Array) {
      result[RightNavBarOption[record_type]][field_name].push(newPerson);
    } else {
      result[RightNavBarOption[record_type]][field_name] = newPerson;
    }
  };
  people.forEach(assignPerson);
  return result;
};
