import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { connect } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./theme";
import Loader from "./components/Shared/Loader/Loader";
import NotificationComponent from "src/components/Shared/Notification";
import User from "./services/User";
import api, { setToken } from "./services/Api";
import { setUser } from "src/actions/Main";
import Confirmation from "./components/Lawyer/Register/Confirmation/Confirmation";
import DealerConfirmation from "./components/Dealer/Register/Confirmation/Confirmation";
import CompletePaymentAfterConfirmation from "./components/ChooseLawyer/CompletePaymentAfterConfirmation/CompletePaymentAfterConfirmation";
import { boolean } from "smooth-scrollbar/decorators";
import { ToastContainer } from "react-toastify";

const Home = lazy(() => import("./components/Home/Home"));
const Login = lazy(() => import("./components/Login/Login"));
const Register = lazy(() => import("./components/Register/Register"));
const ChooseLawyer = lazy(
  () => import("./components/ChooseLawyer/ChooseLawyer")
);
const LeaveOpinion = lazy(
  () => import("./components/LeaveOpinion/LeaveOpinion")
);
const Page = lazy(() => import("./components/Static/Page"));
const SuccessPayment = lazy(
  () => import("./components/Static/SuccessPayment/SuccessPayment")
);
const NotFound = lazy(() => import("./components/Static/NotFound/NotFound"));
const Privacy = lazy(() => import("./components/Static/Privacy/Privacy"));
const Regulation = lazy(
  () => import("./components/Static/Regulation/Regulation")
);
const ClientRegulations = lazy(
  () => import("./components/Static/ClientRegulation/ClientRegulation")
);
const DealerRegulation = lazy(
  () => import("./components/Static/DealerRegulation/index")
);
const RegisterLawyer = lazy(
  () => import("./components/Lawyer/Register/Register")
);
const LoginLawyer = lazy(() => import("./components/Lawyer/Login/Login"));
const LawyerSchedule = lazy(
  () => import("./components/Lawyer/Schedule/Schedule")
);
const CompleteProfile = lazy(
  () => import("./components/Lawyer/CompleteProfile/CompleteProfile")
);
const CompletePrices = lazy(
  () => import("./components/Lawyer/CompletePrices/CompletePrices")
);
const CallsHistory = lazy(() => import("./components/Customer/Calls"));
const CustomerMessages = lazy(() => import("./components/Customer/Messages"));
const LawyerInfo = lazy(() => import("./components/Lawyer/Info/Info"));

const ServiceHistory = lazy(() => import("./components/Customer/Services"));
const Clients = lazy(() => import("./components/Lawyer/Clients"));
const Services = lazy(() => import("./components/Lawyer/Services"));
const LawyerCalls = lazy(() => import("./components/Lawyer/Calls"));
const LawyerMessages = lazy(() => import("./components/Lawyer/Messages"));

const VideoChat = lazy(() => import("./components/VideoChat/VideoChat"));

const DomainsList = lazy(() => import("./components/DomainsList/DomainsList"));
const DomainPage = lazy(() => import("./components/DomainPage/DomainPage"));

const DealerRegister = lazy(
  () => import("./components/Dealer/Register/Register")
);
const DealerLogin = lazy(() => import("./components/Dealer/Login/Login"));
const DealerClients = lazy(() => import("./components/Dealer/Clients"));

const BlogContainer = lazy(() => import("./Blog/BlogContainer"));
const Blog = lazy(() => import("./Blog/Blog"));
const BlogPostCont = lazy(() => import("./Blog/BlogPost/BlogPostCont"));
const BlogCategoryCont = lazy(
  () => import("./Blog/BlogCategory/BlogCategoryCont")
);

const SecretaryClients = lazy(() => import("./components/Secretary/Clients"));
const SecretaryServices = lazy(() => import("./components/Secretary/Services"));
const SecretaryCalls = lazy(() => import("./components/Secretary/Calls"));
const SecretaryMessages = lazy(() => import("./components/Secretary/Messages"));
const SecretaryLogin = lazy(() => import("./components/Secretary/Login/Login"));


const AnalyzesServices = lazy(() => import("./components/Analyzes/Services"));

class App extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      completeProfile: true,
      completePrices: true,
      loaded: false,
    };
  }

  componentDidMount() {
    this.updateUserData();
  }

  updateUserData = () => {
    setToken();
    return new Promise((resolve, reject) => {
      resolve(User.getUserData(resolve, reject));
    })
      .then((user) => {
        if (user) this.props.setUser(user);

        const isLawyer = user && user.role === "lawyer" ? true : false;

        this.setState({
          user: user,
          loaded: !isLawyer,
        });

        if (isLawyer) this.updateLawyerData();
      })
      .catch(() => {
        this.setState({
          loaded: true,
        });
      });
  };

  updateLawyerData = () => {
    if (this.state.user && this.state.user.lawyerId)
      return User.getLayerData(this.state.user.lawyerId).then((lawyer) => {
        this.setState({
          lawyer: lawyer,
          completeProfile:
            lawyer.description && lawyer.education && lawyer.imageId
              ? true
              : false,
          loaded: true,
        });
        return this.updateLawyerServices();
      });
  };

  updateLawyerServices = () => {
    if (this.state.lawyer) {
      return api
        .get("lawyers/" + this.state.user.lawyerId + "/services")
        .then((response) => {
          if (response.data.data.length) {
            let foundDomains = [];
            let foundEmpty = false;

            response.data.data.map((priceList) => {
              foundDomains.push(priceList.Domain.id);
              if (priceList.price) {
                if (priceList.SubServices.length) {
                  priceList.SubServices.map((subService) => {
                    if (!subService.name || subService.name === "<p><br></p>")
                      foundEmpty = true;
                  });
                } else foundEmpty = true;
              } else foundEmpty = true;
            });

            this.state.lawyer.domains &&
              this.state.lawyer.domains.map((domain) => {
                if (!foundDomains.includes(domain.id)) foundEmpty = true;
              });

            this.setState({
              completePrices: !foundEmpty,
            });
          } else
            this.setState({
              completePrices: false,
            });
        });
    }
  };

  render() {
    const { user, lawyer } = this.state;

    return (
      <Suspense fallback={<Loader preLoad={true} />}>
        {this.state.loaded && (
          <ThemeProvider theme={theme}>
            {/*<div className={styles.App}>*/}
            <Router>
              <Switch>
                <Route
                  exact
                  path="/session/:sid"
                  exact
                  render={(props) => (
                    <VideoChat {...props} user={this.state.user} />
                  )}
                />
                <Route
                  exact
                  path="/"
                  render={(props) => {
                    if (user) {
                      switch (user.role) {
                        case "lawyer": {
                          return <Redirect to="/lawyer/schedule" />;
                        }

                        case "secretary": {
                          return <Redirect to="/secretary/clients" />;
                        }
                      }
                    }
                    return (
                      <Home
                        {...props}
                        user={this.state.user}
                        displayCopy={"strony głównej"}
                      />
                    );
                  }}
                />
                <Route
                  path="/kontakt"
                  render={(props) => (
                    <Home
                      {...props}
                      user={this.state.user}
                      scrollToContact={true}
                    />
                  )}
                />
                <Route exact path="/lawyer/info" component={LawyerInfo} />
                <Route
                  path="/:code/info"
                  render={(props) => (
                    <Home
                      {...props}
                      user={this.state.user}
                      scrollToContact={false}
                      displayCopy={"strony głównej"}
                    />
                  )}
                />
                <Route
                  path="/leaveOpinion/:aid"
                  render={(props) => <LeaveOpinion {...props} />}
                />
                <Route
                  path="/login"
                  render={(props) => (
                    <Login {...props} updateUserData={this.updateUserData} />
                  )}
                />
                <Route path="/register" component={Register} />
                <Route
                  path="/chooseLawyer/:domainId"
                  render={(props) => (
                    <ChooseLawyer {...props} user={this.state.user} />
                  )}
                />
                <Route
                  path="/completePayment"
                  render={(props) => (
                    <CompletePaymentAfterConfirmation
                      {...props}
                      user={this.state.user}
                    />
                  )}
                />
                <Route
                  path="/regulamin"
                  render={(props) => (
                    <Page {...props}>
                      <Regulation />
                    </Page>
                  )}
                />
                <Route
                  exact
                  path="/blog"
                  render={(props) => (
                    <BlogContainer {...props} user={this.props.userObj}>
                      <BlogCategoryCont {...props} />
                    </BlogContainer>
                  )}
                />
                <Route
                  exact
                  path="/blog/:domainSlug"
                  render={(props) => (
                    //    <BlogContainer {...props}><Blog /></BlogContainer>)}
                    <BlogContainer {...props} user={this.props.userObj}>
                      <Blog {...props} />
                    </BlogContainer>
                  )}
                />
                <Route
                  exact
                  path="/blog/:domainSlug/:slug"
                  render={(props) => (
                    <BlogContainer
                      {...props}
                      user={this.props.userObj}
                      displayCopy={"artykułu"}
                    >
                      <BlogPostCont {...props} />
                    </BlogContainer>
                  )}
                />
                <Route
                  exact
                  path="/blog/:code/:domainSlug/:slug"
                  render={(props) => (
                    <BlogContainer
                      {...props}
                      user={this.props.userObj}
                      displayCopy={"artykułu"}
                    >
                      <BlogPostCont {...props} />
                    </BlogContainer>
                  )}
                />
                {/* :code */}
                {/* KONIEC NOWE */}
                <Route
                  path="/klient-regulamin"
                  render={(props) => (
                    <Page {...props}>
                      <ClientRegulations />
                    </Page>
                  )}
                />
                <Route
                  path="/partner-regulamin"
                  render={(props) => (
                    <Page {...props}>
                      <DealerRegulation />
                    </Page>
                  )}
                />
                <Route
                  path="/polityka-prywatnosci"
                  render={(props) => (
                    <Page {...props}>
                      <Privacy />
                    </Page>
                  )}
                />
                <Route
                  path="/completePayment"
                  render={(props) => (
                    <CompletePaymentAfterConfirmation
                      {...props}
                      user={this.state.user}
                    />
                  )}
                />
                <Route
                  path="/successPayment"
                  render={(props) => (
                    <Page {...props} updateUserData={this.updateUserData}>
                      <SuccessPayment />
                    </Page>
                  )}
                />
                <Route path="/lawyerConfirmation">
                  <Confirmation />
                </Route>
                <Route
                  path="/lawyer/login"
                  render={(props) => (
                    <LoginLawyer
                      {...props}
                      updateUserData={this.updateUserData}
                    />
                  )}
                />
                <Route path="/lawyer/register" component={RegisterLawyer} />
                <Route
                  path="/lawyer/profile"
                  render={
                    user
                      ? user.role == "lawyer"
                        ? lawyer
                          ? (props) => (
                              <CompleteProfile
                                {...props}
                                user={this.state.user}
                                lawyer={lawyer}
                                updateLawyerData={this.updateLawyerData}
                                completePrices={this.state.completePrices}
                                completeProfile={this.state.completeProfile}
                              />
                            )
                          : () => <Redirect to="/" />
                        : () => <Redirect to="/" />
                      : () => <Redirect to="/" />
                  }
                />
                <Route
                  path="/lawyer/prices"
                  render={
                    this.state.user
                      ? lawyer
                        ? this.state.completeProfile
                          ? (props) => (
                              <CompletePrices
                                {...props}
                                user={this.state.user}
                                lawyer={lawyer}
                                updateLawyerServices={this.updateLawyerServices}
                                completePrices={this.state.completePrices}
                              />
                            )
                          : () => <Redirect to="/lawyer/profile" />
                        : () => <Redirect to="/" />
                      : () => <Redirect to="/" />
                  }
                />
                <Route
                  path="/lawyer/schedule"
                  render={
                    user
                      ? lawyer
                        ? this.state.completeProfile
                          ? this.state.completePrices
                            ? (props) => (
                                <LawyerSchedule
                                  {...props}
                                  user={this.state.user}
                                  lawyer={lawyer}
                                  completePrices={this.state.completePrices}
                                />
                              )
                            : () => <Redirect to="/lawyer/prices" />
                          : () => <Redirect to="/lawyer/profile" />
                        : () => <Redirect to="/" />
                      : () => <Redirect to="/" />
                  }
                />
                <Route
                  path="/lawyer/clients"
                  render={
                    this.state.user
                      ? (props) => (
                          <Clients
                            user={this.state.user}
                            lawyer={lawyer}
                            completePrices={this.state.completePrices}
                            {...props}
                          />
                        )
                      : () => <Redirect to="/" />
                  }
                />
                <Route
                  exact
                  path="/lawyer/services"
                  render={
                    this.state.user
                      ? (props) => (
                          <Services
                            user={this.state.user}
                            lawyer={lawyer}
                            completePrices={this.state.completePrices}
                            {...props}
                          />
                        )
                      : () => <Redirect to="/" />
                  }
                />
                <Route
                  path="/lawyer/services/link"
                  render={
                    this.state.user
                      ? (props) => (
                          <Services
                            user={this.state.user}
                            lawyer={lawyer}
                            completePrices={this.state.completePrices}
                            {...props}
                          />
                        )
                      : () => <Redirect to="/" />
                  }
                />
                <Route
                  path="/lawyer/calls"
                  render={
                    this.state.user
                      ? (props) => (
                          <LawyerCalls
                            user={this.state.user}
                            lawyer={lawyer}
                            completePrices={this.state.completePrices}
                            {...props}
                          />
                        )
                      : () => <Redirect to="/" />
                  }
                />
                <Route
                  path="/lawyer/messages"
                  render={
                    this.state.user
                      ? (props) => (
                          <LawyerMessages
                            user={this.state.user}
                            lawyer={lawyer}
                            completePrices={this.state.completePrices}
                            {...props}
                          />
                        )
                      : () => <Redirect to="/" />
                  }
                />
                <Route path="/partner/register" component={DealerRegister} />
                <Route
                  path="/partner/login"
                  render={(props) => (
                    <DealerLogin
                      {...props}
                      updateUserData={this.updateUserData}
                    />
                  )}
                />
                <Route
                  path="/partner/confirmation"
                  component={DealerConfirmation}
                />
                <Route
                  path="/partner/clients"
                  render={
                    this.state.user
                      ? (props) => (
                          <DealerClients user={this.state.user} {...props} />
                        )
                      : () => <Redirect to="/" />
                  }
                />
                <Route
                  path="/customer/calls"
                  render={
                    this.state.user
                      ? (props) => (
                          <CallsHistory user={this.state.user} {...props} />
                        )
                      : () => <Redirect to="/" />
                  }
                />
                <Route
                  exact
                  path="/customer/services"
                  render={
                    this.state.user
                      ? (props) => (
                          <ServiceHistory {...props} user={this.state.user} />
                        )
                      : () => <Redirect to="/" />
                  }
                />
                <Route
                  path="/customer/services/:tmpServiceId"
                  render={
                    this.state.user
                      ? (props) => (
                          <ServiceHistory {...props} user={this.state.user} />
                        )
                      : () => <Redirect to="/" />
                  }
                />
                <Route
                  exact
                  path="/customer/messages"
                  render={
                    this.state.user
                      ? (props) => (
                          <CustomerMessages {...props} user={this.state.user} />
                        )
                      : () => <Redirect to="/" />
                  }
                />
                <Route
                  path="/customer/messages/:tmpMessageId"
                  render={
                    this.state.user
                      ? (props) => (
                          <CustomerMessages {...props} user={this.state.user} />
                        )
                      : () => <Redirect to="/" />
                  }
                />
                <Route
                  exact
                  path="/dziedziny-prawa"
                  render={(props) => (
                    <DomainsList {...props} user={this.props.userObj} />
                  )}
                />
                <Route
                  exact
                  path="/dziedziny-prawa/:domainId"
                  render={(props) => (
                    <DomainPage
                      {...props}
                      user={this.props.userObj}
                      displayCopy={"dziedziny prawa"}
                    />
                  )}
                />
                <Route
                  exact
                  path="/dziedziny-prawa/:code/:domainId"
                  render={(props) => (
                    <DomainPage
                      {...props}
                      user={this.props.userObj}
                      displayCopy={"dziedziny prawa"}
                    />
                  )}
                ></Route>
                <Route
                  path="/secretary/clients"
                  render={
                    user && user.role === "secretary"
                      ? (props) => (
                          <SecretaryClients
                            user={this.state.user}
                            lawyer={lawyer}
                            completePrices={this.state.completePrices}
                            {...props}
                          />
                        )
                      : () => <Redirect to="/secretary/login" />
                  }
                />
                <Route
                  exact
                  path="/secretary/services"
                  render={
                    user && user.role === "secretary"
                      ? (props) => (
                          <SecretaryServices
                            user={this.state.user}
                            lawyer={lawyer}
                            completePrices={this.state.completePrices}
                            {...props}
                          />
                        )
                      : () => <Redirect to="/secretary/login" />
                  }
                />
                <Route
                  path="/secretary/services/link"
                  render={
                    user && user.role === "secretary"
                      ? (props) => (
                          <SecretaryServices
                            user={this.state.user}
                            lawyer={lawyer}
                            completePrices={this.state.completePrices}
                            {...props}
                          />
                        )
                      : () => <Redirect to="/" />
                  }
                />
                <Route
                  path="/secretary/calls"
                  render={
                    user && user.role === "secretary"
                      ? (props) => (
                          <SecretaryCalls
                            user={this.state.user}
                            lawyer={lawyer}
                            completePrices={this.state.completePrices}
                            {...props}
                          />
                        )
                      : () => <Redirect to="/secretary/login" />
                  }
                />
                <Route
                  path="/secretary/messages"
                  render={
                    user && user.role === "secretary"
                      ? (props) => (
                          <SecretaryMessages
                            user={this.state.user}
                            lawyer={lawyer}
                            completePrices={this.state.completePrices}
                            {...props}
                          />
                        )
                      : () => <Redirect to="/secretary/login" />
                  }
                />
                 <Route
                  exact
                  path="/analyzes/services"
                  render={
                    this.state.user
                      ? (props) => (
                          <AnalyzesServices
                            user={this.state.user}
                            lawyer={lawyer}
                            completePrices={this.state.completePrices}
                            {...props}
                          />
                        )
                      : () => <Redirect to="/" />
                  }
                />
                <Route
                  path="/secretary/login"
                  render={(props) => (
                    <SecretaryLogin
                      {...props}
                      updateUserData={this.updateUserData}
                    />
                  )}
                />
                <Route>
                  <Page>
                    <NotFound />
                  </Page>
                </Route>
              </Switch>
            </Router>
            {/*</div>*/}
            <NotificationComponent />
          </ThemeProvider>
        )}
      </Suspense>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userObj: state.getIn(["main", "user"]),
  };
};

const mapDispatchToProps = {
  setUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
