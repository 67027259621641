import { List, fromJS } from "immutable";
import { ActionType } from "typesafe-actions";

import { ImmutableMap } from "src/helpers/ImmutableHelpers";
import { ServiceTypesActionTypes } from "src/action-types/ServiceTypes";
import * as actions from "src/actions/ServiceTypes";

export interface IServiceType {
  id: number;
  name: string;
}

export type ServiceTypeActions = ActionType<typeof actions>;

export type ServiceTypeState = List<ImmutableMap<IServiceType>>;

const initialState: ServiceTypeState = List();

export default function serviceTypes(
  state: ServiceTypeState = initialState,
  action: ServiceTypeActions
): ServiceTypeState {
  switch (action.type) {
    case ServiceTypesActionTypes.SET:
      return fromJS(action.payload);
    default:
      return state;
  }
}
