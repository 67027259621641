import React from "react";
import ReactDOM from "react-dom";
import "./global.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";

import store from "./store";
import "src/i18n";

// import i18n from "i18next";
// import { useTranslation, initReactI18next } from "react-i18next";

// i18n
//     .use(initReactI18next)
//     .init({
//         resources: {
//             pl: {
//                 translation: {
//                     "Welcome to React": "Welcome to React and react-i18next"
//                 }
//             }
//         },
//         lng: "pl",
//         fallbackLng: "pl",
//
//         interpolation: {
//             escapeValue: false
//         }
//     });

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// function silentErrorHandler() {return true;}
// window.onerror=silentErrorHandler;
