const json = {
  labels: {
    collisionDateTitle: {
      type: "label",
      label: "Data i miejsce wypadku",
    },
    aboutInjuredTitle: {
      type: "label",
      label: "O poszkodowanym",
    },
    collisionDescriptionTitle: {
      type: "label",
      label: "Opis wypadku",
    },
    addressFormTitle: {
      type: "label",
      label: "Adres zameldowania",
    },
    correspondenceAddressFormTitle: {
      type: "label",
      label: "Adres korespondencyjny",
      references: [
        {
          select: "select_2",
          condition: "obrażenia",
        },
      ],
    },
    carInformationFormTitle: {
      type: "label",
      label: "Pojazd",
    },
    contactPersonFormTitle: {
      type: "label",
      label: "Osoba do kontaktu",
    },
    insuranceInfoFormTitle: {
      type: "label",
      label: "Ubezpieczenie",
    },
    perpetratorTitle: {
      type: "label",
      label: "Sprawca",
    },
    insuranceCompanyTitle: {
      type: "label",
      label: "Ubezpieczyciel",
    },
    responsibleTitle: {
      type: "label",
      label: "Odpowiedzialny",
    },
    proxyTitle: {
      type: "label",
      label: "Pełnomocnik",
    },
    processRepresentativeTitle: {
      type: "label",
      label: "Reprezentant procesowy",
    },
    officeFormTitle: {
      type: "label",
      label: "Urząd",
    },
    relationshipDescriptionLabel: {
      type: "label",
      label: "Opis relacji",
    },
    treatmentDescription: {
      type: "label",
      label: "Opis leczenia / Stan po zdarzeniu",
    },
    bodyDamageLabel: {
      type: "label",
      label: "Obrażenia ciała",
    },
  },
  step_row: {
    type: "stepRow",
    label: "Treść",
    select_type_options: [
      { value: 1, label: "Notatka" },
      { value: 2, label: "Etap" },
    ],
    select_version_options: [
      { value: 0, label: "Wypłata" },
      { value: 1, label: "KL" },
      { value: 2, label: "Rozprawa" },
      { value: 3, label: "Ugoda" },
      { value: 4, label: "Pismo" },
      { value: 5, label: "Monit" },
    ],
    date_picker_validation: {
      required: true,
      type: "date",
      "pattern?": "",
    },
    select_validation: {
      required: true,
      type: "string",
      "pattern?": "",
    },
    description_validation: {
      required: true,
      type: "string",
      "pattern?": "",
    },
  },
  name: {
    type: "input",
    label: "Nazwa",
    name: "first_name",
  },

  autocomplete: {
    type: "autocomplete",
    label: "Nazwa",
    name: "first_name",
  },

  phone: {
    type: "input",
    name: "phone",
    label: "Numer telefonu (+00 000 000 000)",
    // tslint:disable-next-line:max-line-length
    pattern:
      "^[+]\\d\\d(([ ]?((\\d{9})|(\\d{7})))|((([ ]\\d{3}[ ]\\d{3}[ ])|([ ]\\d\\d[ ]\\d\\d[ ]))\\d{3}))$",
    validation: {
      required: true,
      type: "string",
      "pattern?": "",
    },
  },
  email: {
    type: "input",
    name: "email",
    label: "E-mail",
    pattern:
      "^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$",
    validation: {
      required: true,
      type: "string",
      "pattern?": "",
    },
  },
  additional_information: {
    name: "additional_information",
    type: "input",
    label: "Informacje dodatkowe",
    validation: {
      required: true,
      type: "string",
      "pattern?": "",
    },
  },

  companyDataForm: {
    NIP: {
      type: "input",
      label: "NIP",
      name: "NIP",
      pattern: "^[0-9]{10}$",
      validation: {
        required: true,
        type: "string",
        "pattern?": "",
      },
    },
    REGON: {
      type: "input",
      label: "REGON",
      name: "REGON",
      pattern: "^[0-9]{9}$",
      validation: {
        required: true,
        type: "string",
        "pattern?": "",
      },
    },
    KRS: {
      type: "input",
      label: "KRS",
      name: "KRS",
      validation: {
        required: true,
        type: "string",
        "pattern?": "",
      },
    },
  },
  contactPersonForm: {
    last_name: {
      type: "autocomplete",
      name: "last_name",
      label: "Nazwisko",
      validation: {
        required: true,
        type: "string",
        "pattern?": "",
      },
    },
    first_name: {
      type: "input",
      name: "first_name",
      label: "Imie",
      validation: {
        required: true,
        type: "string",
        "pattern?": "",
      },
    },
    position: {
      type: "input",
      name: "position",
      label: "Stanowisko",
      validation: {
        required: true,
        type: "string",
        "pattern?": "",
      },
    },
    phone: {
      type: "input",
      name: "phone",
      label: "Numer telefonu (+00 000 000 000)",
      // tslint:disable-next-line:max-line-length
      pattern:
        "^[+]\\d\\d(([ ]?((\\d{9})|(\\d{7})))|((([ ]\\d{3}[ ]\\d{3}[ ])|([ ]\\d\\d[ ]\\d\\d[ ]))\\d{3}))$",
      validation: {
        required: true,
        type: "number",
        "pattern?": "",
      },
    },
    email: {
      type: "input",
      name: "email",
      label: "E-mail",
      pattern:
        "^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$",
      validation: {
        required: true,
        type: "number",
        "pattern?": "",
      },
    },
    additional_information: {
      type: "input",
      name: "additional_information",
      label: "Informacje dodatkowe",
      validation: {
        required: true,
        type: "string",
        "pattern?": "",
      },
    },
  },

  representativeForm: {
    representative_last_name: {
      type: "autocomplete",
      name: "representative_last_name",
      label: "Nazwisko",
      validation: {
        required: true,
        type: "string",
        "pattern?": "",
      },
    },
    representative_first_name: {
      type: "input",
      name: "representative_first_name",
      label: "Imie",
      validation: {
        required: true,
        type: "string",
        "pattern?": "",
      },
    },
    representative_position: {
      type: "input",
      name: "representative_position",
      label: "Stanowisko",
      validation: {
        required: true,
        type: "string",
        "pattern?": "",
      },
    },
    representative_number: {
      type: "input",
      name: "representative_number",
      label: "Numer telefonu (+00 000 000 000)",
      pattern:
        "^[+]\\d\\d(([ ]?((\\d{9})|(\\d{7})))|((([ ]\\d{3}[ ]\\d{3}[ ])|([ ]\\d\\d[ ]\\d\\d[ ]))\\d{3}))$",
      validation: {
        required: true,
        type: "number",
        "pattern?": "",
      },
    },
    representative_email: {
      type: "input",
      name: "representative_email",
      label: "E-mail",
      pattern:
        "^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$",
      validation: {
        required: true,
        type: "number",
        "pattern?": "",
      },
    },
  },
  officeForm: {
    first_name: {
      type: "autocomplete",
      label: "Nazwa",
      name: "first_name",
      validation: {
        required: true,
        type: "string",
        "pattern?": "",
      },
    },
    office_department: {
      name: "office_department",
      type: "autocomplete",
      label: "Wydział",
      validation: {
        required: true,
        type: "string",
        "pattern?": "",
      },
    },
    street: {
      name: "street",
      type: "input",
      label: "Ulica",
      validation: {
        required: true,
        type: "string",
        "pattern?": "",
      },
    },
    house_number: {
      name: "house_number",
      type: "input",
      label: "Nr domu",
      validation: {
        required: true,
        type: "number",
        "pattern?": "",
      },
    },
    city: {
      name: "city",
      type: "input",
      label: "Miejscowość",
      validation: {
        required: true,
        type: "string",
        "pattern?": "",
      },
    },
    post_code: {
      name: "post_code",
      type: "input",
      label: "Kod pocztowy",
      pattern: "\\d{2}-\\d{3}",
      validation: {
        required: true,
        type: "number",
        "pattern?": "",
      },
    },
    phone: {
      name: "phone",
      label: "Numer telefonu (+00 000 000 000)",
      // tslint:disable-next-line:max-line-length
      pattern:
        "^[+]\\d\\d(([ ]?((\\d{9})|(\\d{7})))|((([ ]\\d{3}[ ]\\d{3}[ ])|([ ]\\d\\d[ ]\\d\\d[ ]))\\d{3}))$",
      type: "input",
      validation: {
        required: true,
        type: "number",
        "pattern?": "",
      },
    },
    phone_2: {
      name: "phone_2",
      label: "Numer telefonu (+00 000 000 000)",
      // tslint:disable-next-line:max-line-length
      pattern:
        "^[+]\\d\\d(([ ]?((\\d{9})|(\\d{7})))|((([ ]\\d{3}[ ]\\d{3}[ ])|([ ]\\d\\d[ ]\\d\\d[ ]))\\d{3}))$",
      type: "input",
      validation: {
        required: true,
        type: "number",
        "pattern?": "",
      },
    },
    phone_3: {
      name: "phone_3",
      label: "Numer telefonu (+00 000 000 000)",
      // tslint:disable-next-line:max-line-length
      pattern:
        "^[+]\\d\\d(([ ]?((\\d{9})|(\\d{7})))|((([ ]\\d{3}[ ]\\d{3}[ ])|([ ]\\d\\d[ ]\\d\\d[ ]))\\d{3}))$",
      type: "input",
      validation: {
        required: true,
        type: "number",
        "pattern?": "",
      },
    },
    email: {
      type: "input",
      name: "email",
      label: "E-mail",
      pattern:
        "^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$",
      validation: {
        required: true,
        type: "string",
        "pattern?": "",
      },
    },
    signature: {
      type: "input",
      name: "signature",
      label: "Sygnatura sprawy",
    },
  },

  collisionDate: {
    date: {
      name: "date",
      type: "datePicker",
      label: "Data",
      format: "DD/MM/YYYY",
      validation: {
        required: true,
        type: "date",
        "pattern?": "",
      },
    },
    hour: {
      name: "hour",
      type: "input",
      additionalClass: "half__left",
      label: "Godzina",
      pattern: "^(?:([01]?\\d|2[0-3]):([0-5]?\\d))$",
      validation: {
        required: true,
        type: "string",
        "pattern?": "",
      },
    },
    location: {
      name: "location",
      type: "input",
      additionalClass: "half__right",
      label: "Miejsce",
      validation: {
        required: true,
        type: "string",
        "pattern?": "",
      },
    },
  },

  aboutInjured: {
    role: {
      name: "role",
      type: "checkbox",
      label: "Rola poszkodowanego",
      options: [
        {
          label: "Kierowca",
          type: "checkbox",
        },
        {
          label: "Pasażer",
          type: "checkbox",
        },
        {
          label: "Pieszy",
          type: "checkbox",
        },
        {
          label: "Rowerzysta",
          type: "checkbox",
        },
        {
          label: "Inna",
          type: "input",
        },
      ],
      validation: {
        required: true,
        type: "string",
        "pattern?": "",
      },
    },
    car: {
      name: "car",
      type: "input",
      label: "Marka pojazdu poszkodowanego",
      additionalClass: "half__left",
      validation: {
        required: true,
        type: "string",
        "pattern?": "",
      },
    },
    car_info: {
      name: "car_info",
      type: "input",
      label: "Nr rejestracyjny",
      additionalClass: "half__right",
      validation: {
        required: true,
        type: "string",
        "pattern?": "",
      },
    },
    belts: {
      name: "belts",
      type: "checkbox",
      label: "Zapięte pasy",
      options: [
        {
          label: "tak",
          type: "checkbox",
        },
        {
          label: "nie",
          type: "checkbox",
        },
        {
          label: "b/d",
          type: "checkbox",
        },
      ],
    },
    drugs: {
      name: "drugs",
      type: "checkbox",
      label: "Alkohol, narkotyki, inne",
      options: [
        {
          label: "tak",
          type: "checkbox",
        },
        {
          label: "nie",
          type: "checkbox",
        },
        {
          label: "b/d",
          type: "checkbox",
        },
      ],
    },
  },

  collisionDescription: {
    description: {
      name: "description",
      type: "textarea",
      label: "Opis wypadku",
      validation: {
        required: true,
        type: "string",
        "pattern?": "",
      },
    },
  },

  police_checkbox: {
    name: "police",
    label: "Policja",
    type: "checkbox",
    options: [
      {
        label: "tak",
        type: "checkbox",
      },
      {
        label: "nie",
        type: "checkbox",
      },
      {
        label: "b/d",
        type: "checkbox",
      },
      {
        label: "skąd",
        type: "input",
        condition: "tak",
      },
    ],
  },

  prosecutors_checkbox: {
    name: "prosecutors",
    label: "Prokuratura",
    type: "checkbox",
    options: [
      {
        label: "tak",
        type: "checkbox",
      },
      {
        label: "nie",
        type: "checkbox",
      },
      {
        label: "b/d",
        type: "checkbox",
      },
      {
        label: "skąd",
        type: "input",
        condition: "tak",
      },
    ],
  },

  law_court_checkbox: {
    name: "law_court",
    label: "Sąd",
    type: "checkbox",
    options: [
      {
        label: "tak",
        type: "checkbox",
      },
      {
        label: "nie",
        type: "checkbox",
      },
      {
        label: "b/d",
        type: "checkbox",
      },
      {
        label: "skąd",
        type: "input",
        condition: "tak",
      },
    ],
  },

  emergency_checkbox: {
    name: "emergency",
    label: "Pogotowie",
    type: "checkbox",
    options: [
      {
        label: "tak",
        type: "checkbox",
      },
      {
        label: "nie",
        type: "checkbox",
      },
      {
        label: "b/d",
        type: "checkbox",
      },
      {
        label: "skąd",
        type: "input",
        condition: "tak",
      },
    ],
  },

  death_date: {
    name: "death_date",
    type: "datePicker",
    format: "DD/MM/YYYY",
    label: "Data zgonu",
    // tslint:disable-next-line:max-line-length
    additional_label: "jeżeli inna niż data wypadku",
    references: [
      {
        select: "select_2",
        condition: "śmiertelna",
      },
    ],
    validation: {
      required: false,
      type: "date",
      "pattern?": "",
    },
  },

  martial_status: {
    name: "martial_status",
    type: "input",
    label: "Stan cywilny",
    references: [
      {
        select: "select_2",
        condition: "śmiertelna",
      },
    ],
    validation: {
      required: true,
      type: "string",
      "pattern?": "",
    },
  },

  basePersonalInfoForm: {
    first_name: {
      name: "first_name",
      type: "input",
      label: "Imię",
      validation: {
        required: true,
        type: "string",
        "pattern?": "",
      },
    },
    last_name: {
      name: "last_name",
      type: "input",
      label: "Nazwisko",
      validation: {
        required: true,
        type: "string",
        "pattern?": "",
      },
    },
    pesel: {
      name: "pesel",
      type: "input",
      label: "Pesel",
      pattern: "^[0-9]{11}$",
      additionalClass: "half__left",
      validation: {
        required: true,
        type: "number",
        "pattern?": "",
      },
    },
    birth_date: {
      name: "birth_date",
      label: "Data urodzenia",
      type: "datePicker",
      format: "DD/MM/YYYY",
      additionalClass: "half__right",
      // tslint:disable-next-line:max-line-length
      validation: {
        required: true,
        type: "string",
        "pattern?": "",
      },
    },
  },
  addressForm: {
    street__name: {
      name: "street",
      type: "input",
      label: "Ulica",
      validation: {
        required: true,
        type: "string",
        "pattern?": "",
      },
    },
    house_number: {
      name: "house_number",
      type: "input",
      label: "Nr domu",
      additionalClass: "half__left",
      validation: {
        required: true,
        type: "number",
        "pattern?": "",
      },
    },
    apartment_number: {
      name: "apartment",
      type: "input",
      label: "Nr mieszkania",
      additionalClass: "half__right",
      validation: {
        required: true,
        type: "number",
        "pattern?": "",
      },
    },
    post_code: {
      name: "post_code",
      type: "input",
      label: "Kod pocztowy",
      additionalClass: "half__left",
      pattern: "\\d{2}-\\d{3}",
      validation: {
        required: true,
        type: "number",
        "pattern?": "",
      },
    },
    city: {
      name: "city",
      type: "input",
      label: "Miejscowość",
      additionalClass: "half__right",
      validation: {
        required: true,
        type: "string",
        "pattern?": "",
      },
    },
  },
  correspondenceAddressForm: {
    correspondence_street__name: {
      name: "correspondence_street",
      type: "input",
      label: "Ulica",
      references: [
        {
          select: "select_2",
          condition: "obrażenia",
        },
      ],
      validation: {
        required: true,
        type: "string",
        "pattern?": "",
      },
    },
    correspondence_house_number: {
      name: "correspondence_house_number",
      type: "input",
      label: "Nr domu",
      additionalClass: "half__left",
      references: [
        {
          select: "select_2",
          condition: "obrażenia",
        },
      ],
      validation: {
        required: true,
        type: "number",
        "pattern?": "",
      },
    },
    correspondence_apartment_number: {
      name: "correspondence_apartment",
      type: "input",
      label: "Nr mieszkania",
      additionalClass: "half__right",
      references: [
        {
          select: "select_2",
          condition: "obrażenia",
        },
      ],
      validation: {
        required: true,
        type: "number",
        "pattern?": "",
      },
    },
    correspondence_post_code: {
      name: "correspondence_post_code",
      type: "input",
      label: "Kod pocztowy",
      additionalClass: "half__left",
      pattern: "\\d{2}-\\d{3}",
      references: [
        {
          select: "select_2",
          condition: "obrażenia",
        },
      ],
      validation: {
        required: true,
        type: "number",
        "pattern?": "",
      },
    },
    correspondence_city: {
      name: "correspondence_city",
      type: "input",
      label: "Miejscowość",
      additionalClass: "half__right",
      references: [
        {
          select: "select_2",
          condition: "obrażenia",
        },
      ],
      validation: {
        required: true,
        type: "string",
        "pattern?": "",
      },
    },
  },
  carInformationForm: {
    car: {
      name: "car",
      type: "input",
      label: "Marka pojazdu",
      validation: {
        required: true,
        type: "string",
        "pattern?": "",
      },
    },
    car_info: {
      name: "car_info",
      type: "input",
      label: "Nr rejestracyjny",
      validation: {
        required: true,
        type: "string",
        "pattern?": "",
      },
    },
    driver: {
      name: "driver",
      label: "Kierujący inny niż właściciel?",
      type: "checkbox",
      options: [
        {
          label: "tak",
          type: "checkbox",
        },
        {
          label: "nie",
          type: "checkbox",
        },
        {
          label: "b/d",
          type: "checkbox",
        },
        {
          type: "input",
          name: "car_owner",
          label: "Właściciel pojazdu",
          condition: "tak",
        },
      ],
    },
  },
  insuranceInfoForm: {
    policy_number: {
      name: "policy_number",
      type: "input",
      label: "Nr polisy",
      validation: {
        required: true,
        type: "string",
        "pattern?": "",
      },
    },
    harm: {
      name: "harm",
      label: "Czy zgłoszono szkodę osobową",
      type: "checkbox",
      options: [
        {
          label: "tak",
          type: "checkbox",
        },
        {
          label: "nie",
          type: "checkbox",
        },
        {
          label: "b/d",
          type: "checkbox",
        },
        {
          name: "harm_date",
          label: "Data zgłoszenia",
          type: "datePicker",
          format: "DD/MM/YYYY",
          // tslint:disable-next-line:max-line-length
          pattern:
            "^((0[1-9])|([1,2][0-9])|(3[0,1]))\\/((0[1-9])|(1[0-2]))\\/[0-9]{4}$",
          condition: "tak",
        },
        {
          name: "harm_number",
          type: "input",
          label: "Nr szkody",
          validation: {
            required: true,
            type: "number",
            "pattern?": "",
          },
          condition: "tak",
        },
      ],
    },
    provision: {
      name: "provision",
      label: "Czy wypłacono świadczenie z OC",
      type: "checkbox",
      options: [
        {
          label: "tak",
          type: "checkbox",
        },
        {
          label: "nie",
          type: "checkbox",
        },
        {
          label: "b/d",
          type: "checkbox",
        },
        {
          name: "provision_date",
          type: "datePicker",
          format: "DD/MM/YYYY",
          label: "Data wypłaty",
          // tslint:disable-next-line:max-line-length
          pattern:
            "^((0[1-9])|([1,2][0-9])|(3[0,1]))\\/((0[1-9])|(1[0-2]))\\/[0-9]{4}$",
          validation: {
            required: true,
            type: "string",
            "pattern?": "",
          },
          condition: "tak",
        },
        {
          name: "provision_amount",
          type: "input",
          label: "Wysokość",
          validation: {
            required: true,
            type: "string",
            "pattern?": "",
          },
          condition: "tak",
        },
      ],
    },
    agreement: {
      name: "agreement",
      label: "Czy poszkodowany podpisał ugodę",
      type: "checkbox",
      options: [
        {
          label: "tak",
          type: "checkbox",
        },
        {
          label: "nie",
          type: "checkbox",
        },
        {
          label: "b/d",
          type: "checkbox",
        },
      ],
    },
  },
  witnessesForm: {
    circumstances: {
      name: "circumstances",
      label: "Na okoliczność",
      type: "autocomplete",
    },
    last_name: {
      type: "input",
      label: "Nazwisko",
      name: "last_name",
    },
    name: {
      type: "input",
      label: "Imię",
      name: "first_name",
    },
    street: {
      type: "input",
      label: "Ulica",
      name: "street",
    },
    house_number: {
      type: "input",
      label: "Numer domu",
      name: "house_number",
    },
    apartment: {
      type: "input",
      label: "Numer lokalu",
      name: "apartment",
    },
    city: {
      type: "input",
      label: "Miasto",
      name: "city",
    },
    post_code: {
      type: "input",
      label: "Kod pocztowy",
      name: "post_code",
      pattern: "\\d{2}-\\d{3}",
    },
    phone: {
      type: "input",
      label: "Numer telefonu (+00 000 000 000)",
      // tslint:disable-next-line:max-line-length
      pattern:
        "^[+]\\d\\d(([ ]?((\\d{9})|(\\d{7})))|((([ ]\\d{3}[ ]\\d{3}[ ])|([ ]\\d\\d[ ]\\d\\d[ ]))\\d{3}))$",
      name: "phone",
    },
    email: {
      type: "input",
      label: "E-mail",
      name: "email",
      pattern:
        "^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$",
    },
  },
  guardian_selector: {
    type: "select",
    name: "guardian",
    options: "clientsList",
  },
  consequences: {
    name: "after_state",
    type: "textarea",
    label: "Opis",
  },
  body_damage: {
    type: "textarea",
    name: "description",
    label: "Opis",
  },
  before_state: {
    name: "after_state",
    type: "textarea",
    label: "Opis",
  },
  additional_information_tab: {
    name: "additional_information",
    type: "textarea",
    label: "Opis",
  },
  relationshipsForm: {
    relationship_description: {
      type: "textarea",
      name: "description",
      label: "Opis",
    },
    kinship_degree: {
      type: "input",
      name: "relation_level",
      additionalClass: "triple__left",
      label: "Stopień pokrewieństwa",
    },
    subject_age: {
      type: "input",
      name: "age_1",
      additionalClass: "triple__center",
      label: "Wiek roszczącego",
    },
    deceased_age: {
      type: "input",
      name: "age_2",
      additionalClass: "triple__right",
      label: "Wiek zmarłego",
    },
    living_together: {
      type: "checkbox",
      name: "common_house",
      label: "Wspólne zamieszkiwanie",
      options: [
        {
          label: "tak",
          type: "checkbox",
        },
        {
          label: "nie",
          type: "checkbox",
        },
        {
          label: "b/d",
          type: "checkbox",
        },
      ],
    },
    living_additional: {
      type: "textarea",
      name: "common_house_description",
      label: "Informacje dodatkowe",
    },
    common_farm: {
      type: "checkbox",
      name: "common_company",
      label: "Wspólne gospodarstwo",
      options: [
        {
          label: "tak",
          type: "checkbox",
        },
        {
          label: "nie",
          type: "checkbox",
        },
        {
          label: "b/d",
          type: "checkbox",
        },
      ],
    },
    farm_additional: {
      type: "textarea",
      name: "common_company_description",
      label: "Informacje dodatkowe",
    },
    treatment_description: {
      type: "textarea",
      name: "after_state",
      additionalClass: "margin__top",
      label: "Opis leczenia / Stan po zdarzeniu",
    },
  },
};

const prioritySelectorsReferences = {
  select_1: {
    3: {
      1: {
        value: 1,
        label: "Osobowa",
      },
      2: {
        value: 2,
        label: "Majątkowa",
      },
    },
  },
  select_2: {
    1: {
      1: {
        value: 1,
        label: "Śmiertelna",
      },
      2: {
        value: 2,
        label: "Obrażenia",
      },
    },
    2: {
      1: {
        value: 1,
        label: "Wykup",
      },
      2: {
        value: 2,
        label: "Proces",
      },
    },
  },
  select_3: {
    1: {
      1: {
        value: 1,
        label: "Komunikacja",
      },
      2: {
        value: 2,
        label: "W pracy",
      },
      3: {
        value: 3,
        label: "Poślizgnięcie",
      },
      4: {
        value: 4,
        label: "Błąd w sztuce lekarskiej",
      },
    },
    2: {
      1: {
        value: 1,
        label: "Komunikacja",
      },
    },
  },
};

export const getObject = () => json;

export const getReferences = () => prioritySelectorsReferences;
