import * as React from "react";
import { connect } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppState } from "src/reducers";

class NotificationComponent extends React.Component<any, any> {
  public componentDidUpdate(prevProps: any): void {
    if (prevProps.notification !== this.props.notification) {
      if (this.props.notification) {
        const { type, text } = this.props.notification;
        this.notify(type, text);
      }
    }
  }

  render() {
    return (
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    );
  }

  notify = (type, text) => {
    switch (type) {
      case "success": {
        toast.success(text, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      }
      case "error": {
        toast.error(text, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      }
    }
  };
}
const mapStateToProps = (state: AppState) => ({
  notification: state.get("main").get("notification"),
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationComponent);
