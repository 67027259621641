import { List, fromJS, Record, Map } from "immutable";
import { ActionType } from "typesafe-actions";

import { ImmutableMap } from "src/helpers/ImmutableHelpers";
import { ServicesActionTypes } from "src/action-types/Services";
import * as actions from "src/actions/Services";

import { IServiceUser } from "src/reducers/UpsertServiceForm";
import {
  parseServiceList,
  setServiceDetails,
} from "src/transformators/Services";
// import {
//   IServiceListElement,
//   IServiceStepForm,
//   IServiceMessage,
//   ITemplate
// } from 'lawpoint-common/typing/staff-panel';
// import { IDocumentBodyData, FileType } from 'lawpoint-common/typing/common';

export type ServicesActions = ActionType<typeof actions>;

interface ICompany {
  city: string | null;
  house_number: string | null;
  name: string | null;
  post_code: string | null;
  street: string | null;
  nip: string | null;
}

export interface IServiceFile {
  id: number;
  created_at: string;
  original_name: string;
  type: any;
}

export interface IClient {
  id: number;
  first_name: string | null;
  last_name: string | null;
  city: string | null;
  email: string | null;
  house_number: string | null;
  phone: string | null;
  post_code: string | null;
  street: string | null;
}

export interface IServiceCall {
  amount: number | null;
  duration: number | null;
  caller_id: number | null;
  created_at: string | null;
  type: number;
}

// export interface IServiceElement extends IServiceListElement {
//   serviceType: string;
//   service_id: number;
// }

export interface IServiceDetails {
  service_id: number | null;
  people: IServiceUser[];
  files: IServiceFile[];
  description: string | null;
  company: ICompany;
  client: IClient;
  steps: any[];
  calls: IServiceCall[];
  additional_information: string | null;
  consequences: string | null;
  messages: any[];
  templates: any[];
  documents: any[];
  before_state: string | null;
  sid: number | null;
}

export interface IServiceDetailsImmutable {
  service_id: number | null;
  people: List<ImmutableMap<IServiceUser>>;
  description: string | null;
  company: ImmutableMap<ICompany>;
  client: ImmutableMap<IClient>;
  steps: List<ImmutableMap<any>>;
  calls: List<ImmutableMap<IServiceCall>>;
  additional_information: string | null;
  consequences: string | null;
  messages: List<ImmutableMap<any>>;
  templates: List<ImmutableMap<any>>;
  documents: List<ImmutableMap<any>>;
  before_state: string | null;
  sid: number | null;
  branch_id: number;
  matter: any;
  select1: number;
  select2: number;
  select3: number;
}

export interface IServicesState {
  activeServiceId: number | null;
  changeActiveTab: number | null;
  services: List<ImmutableMap<any>>;
  details: ImmutableMap<IServiceDetailsImmutable>;
  attachment: any | null;
  numPages: number | null;
  upload_finish: boolean | null;
}

export type ServicesState = Record<IServicesState>;

const ServicesState = Record<IServicesState>({
  activeServiceId: null,
  changeActiveTab: null,
  services: List(),
  details: Map(),
  serviceUpdateSuccessful: false,
  serviceListLoading: false,
  attachment: null,
  numPages: 0,
  upload_finish: false
});

const initialState: ServicesState = new ServicesState();

export default function services(
  state: ServicesState = initialState,
  action: ServicesActions
): ServicesState {
  switch (action.type) {
    case ServicesActionTypes.SET_SERVICE_LIST_LOADING:
      return state.set("serviceListLoading", action.payload);
    case ServicesActionTypes.SET_SERVICE_UPDATE_SUCCESSFUL:
      return state.set("serviceUpdateSuccessful", action.payload);
    case ServicesActionTypes.VIEW_ATTACHMENTS_STATE:
       
        return state.set("attachment", action.payload);
    case ServicesActionTypes.CLOSE_VIEW_ATTACHMENTS_STATE:       
          return state.set("attachment", null);
    case ServicesActionTypes.SET_NUM_PAGES:       
          return state.set("numPages", action.payload);

    case ServicesActionTypes.SET_UPLOADED_FINISH:   
          console.log("upload_finish", action.payload);    
          return state.set("upload_finish", action.payload);

          
  
        
    case ServicesActionTypes.ADD_SERVICE_SUCCESS:
      return state;

    case ServicesActionTypes.SERVICE_ID_SET:
      if (action.payload == null) {
        return state
          .set("activeServiceId", action.payload)
          .set("details", Map());
      }
      return state.set("activeServiceId", action.payload);
    case ServicesActionTypes.CHANGE_ACTIVE_TAB:
      return state.set("changeActiveTab", action.payload);
    case ServicesActionTypes.SERVICES_LIST_SET:
      const parsedList = action.payload.service_list;
      return state.set("services", fromJS(parsedList));
    case ServicesActionTypes.SERVICE_DETAILS_SET:
      return state.set("details", fromJS(setServiceDetails(action.payload)));
    case ServicesActionTypes.SERVICE_STEPS_SET:
      return state.setIn(["details", "steps"], action.payload);
    case ServicesActionTypes.EDIT_SERVICE:
      const newData = action.payload;
      const serviceIndex = state
        .get("services")
        .findIndex(
          (el: ImmutableMap<any>) =>
            el.get("id") === state.get("activeServiceId")
        );
      let service: ImmutableMap<any> = state.getIn(["services", serviceIndex]);

      Object.keys(newData).forEach(
        (value) => (service = service.set(value, newData[value]))
      );

      return state.setIn(["services", serviceIndex], service);
    default:
      return state;
  }
}
