import React from "react";
import styles from "./Loader.module.scss";
import { ReactComponent as LoaderImage } from "./../../../assets/svg/LOADER.svg";
import { ReactComponent as Logo } from "./../../../assets/svg/emblemat.svg";
import Fade from "@material-ui/core/Fade";
import classNames from "classnames";

const Loader = (props) => (
  <Fade in={true}>
    <div
      className={classNames({
        [styles.Loader]: true,
        [styles.preLoader]: props.preLoad,
      })}
    >
      <div className={styles.loader}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  </Fade>
);

Loader.defaultProps = {};

export default Loader;
