import { action } from "typesafe-actions";

import { UpsertServiceFormActionTypes } from "src/action-types/UpsertServiceForm";
import {
  ISelectClientValue,
  ISelectedBranch,
} from "src/components/Lawyer/Services/SelectClientModal";
import { ISelectLawyerValue } from "src/components/Secretary/Services/SelectClientModal";
import { ISelectValue } from "src/components/Lawyer/Services/UpsertServiceForm/Common/PrioritySelectors";
import api from "src/services/Api";
import { newDetailObject } from "src/reducers/UpsertServiceForm";
import { createReturn } from "typescript";
import { MainActionTypes } from "src/action-types/Main";
import { ServicesActionTypes } from "src/action-types/Services";
import { ServiceItemMode, ServiceItemType } from "src/types/types";

export interface ISendDataParams {
  route: string;
  data: any;
}

export const saveToServiceForm = (path: string[], value: string) =>
  action(UpsertServiceFormActionTypes.SERVICE_FORM_DATA_SET, { path, value });

export const validateObjectTab = (path: string[], value: string) =>
  action(UpsertServiceFormActionTypes.SET_FORM_TAB_VALIDATION_DATA, {
    path,
    value,
  });

export const setFormData = (path: Array<string>, value: string) =>
  action(UpsertServiceFormActionTypes.SET_FORM_DATA, { path, value });

export const setSelectedClient = (client: ISelectClientValue) =>
  action(UpsertServiceFormActionTypes.SET_CLIENT, client);

export const setSelectedBranch = (branch: ISelectedBranch) =>
  action(UpsertServiceFormActionTypes.SET_SELECTED_CATEGORY, branch);

export const setSelectedType = (type: ISelectedBranch) =>
  action(UpsertServiceFormActionTypes.SET_SELECTED_TYPE, type);

export const setSelectedDealerPosition = (type: ISelectedBranch) =>
  action(UpsertServiceFormActionTypes.SET_SELECTED_DEALER_POSITION, type);

export const setPrioritySelectorsData = (
  inputName: string,
  select: ISelectValue
) =>
  action(UpsertServiceFormActionTypes.SET_PRIORITY_SELECTORS_DATA, {
    inputName,
    select,
  });

export const setSelectedGuardian = (guardian: number | null) =>
  action(UpsertServiceFormActionTypes.SET_GUARDIAN, guardian);

export const setServiceFormDataStatus = (type: string) =>
  action(UpsertServiceFormActionTypes.SET_FORM_DATA_STATUS, type);

export const clearServiceFormData = () =>
  action(UpsertServiceFormActionTypes.CLEAR_FORM_DATA);

export const setServiceFormData = (data: any) =>
  action(UpsertServiceFormActionTypes.SET_SERVICE_FORM_DATA, data);

export const sendServiceFormData =
  (data: ISendDataParams, type: string) => async (dispatch) => {
    let promises = [];
    switch (type) {
      case "description":
        if (data.id) updateDetails(data, dispatch);
        else if (data.registerId) postDetails(data, dispatch);
        break;
      case "state_before":
        if (data.id) updateDetails(data, dispatch);
        else if (data.registerId) postDetails(data, dispatch);
        break;
      case "state_after":
        if (data.id) updateDetails(data, dispatch);
        else if (data.registerId) postDetails(data, dispatch);
        break;
      case "additional_information":
        if (data.id) updateDetails(data, dispatch);
        else if (data.registerId) postDetails(data, dispatch);
        break;
      case "cards":
        promises = [];
        let registerDetailId = data.id;
        if (!registerDetailId) {
          const newDetail = {
            ...newDetailObject.toJS(),
            registerId: data.registerId,
          };
          const detailsRes = await api.post(`/services/details`, newDetail);

          if (detailsRes.data.success)
            registerDetailId = detailsRes.data.data.id;
        }

        data.cards.forEach((card) => {
          if (card.id && !card.isNew && !card.isDeleted) {
            promises.push(updateCard(card, dispatch));
          } else if (card.id && card.isDeleted && !card.isNew) {
            promises.push(removeCard(card, dispatch));
          } else if (!card.isDeleted && card.isNew) {
            card.registerDetailId = registerDetailId;
            promises.push(postCard(card, dispatch));
          }
        });

        return Promise.all(promises)
          .then((res) => {
            dispatch({
              type: UpsertServiceFormActionTypes.SEND_FORM_DATA,
              payload: res,
            });
            dispatch({
              type: MainActionTypes.SET_NOTIFICATION,
              payload: { type: "success", text: "Dane usługi zapisane" },
            });
            dispatch({
              type: ServicesActionTypes.SET_SERVICE_UPDATE_SUCCESSFUL,
              payload: true,
            });
          })
          .catch((err) => {});

        break;
      case "items":
        let shouldChangeActiveTab = 0;
        promises = [];

        data.items.forEach((item) => {
          if (
            item.id &&
            !item.isNew &&
            !item.isDeleted &&
            !item.isSystem &&
            item.isUpdated
          ) {
            promises.push(() => updateItem(item, dispatch));
          } else if (item.id && item.isDeleted && !item.isNew) {
            if (
              item.registerItemTypeId == ServiceItemType.Zakonczone &&
              item.registerItemModeId == ServiceItemMode.Etap
            ) {
              shouldChangeActiveTab = 1;
            }
            promises.push(() => removeItem(item, dispatch));
          } else if (!item.isDeleted && item.isNew) {
            item.registerId = data.registerId;
            if (
              item.registerItemTypeId == ServiceItemType.Zakonczone &&
              item.registerItemModeId == ServiceItemMode.Etap
            ) {
              shouldChangeActiveTab = 2;
            }
            promises.push(() => postItem(item, dispatch));
          }
        });

        return inSequence(promises)
          .then((res) => {
            dispatch({
              type: UpsertServiceFormActionTypes.SEND_FORM_DATA,
              payload: res,
            });
            dispatch({
              type: MainActionTypes.SET_NOTIFICATION,
              payload: { type: "success", text: "Dane usługi zapisane" },
            });
            dispatch({
              type: ServicesActionTypes.SET_SERVICE_UPDATE_SUCCESSFUL,
              payload: true,
            });
            if (shouldChangeActiveTab) {
              dispatch({
                type: ServicesActionTypes.CHANGE_ACTIVE_TAB,
                payload: shouldChangeActiveTab,
              });
            }
          })
          .catch((err) => {
            dispatch({
              type: MainActionTypes.SET_NOTIFICATION,
              payload: { type: "error", text: "Wystąpił błąd" },
            });
          });

        break;
    }
  };

const inSequence = (tasks) => {
  return tasks.reduce((p, task) => p.then(task), Promise.resolve());
};

const updateDetails = async (data, dispatch) => {
  const { id } = data;
  try {
    const res = await api.put(`/services/details/${id}`, data);
    const res2 = await api.put(`/services/${data.registerId}`, {
      domainId: data.domainId,
      domainTypeId: data.domainTypeId,
    });
    dispatch({
      type: UpsertServiceFormActionTypes.SEND_FORM_DATA,
      payload: res,
    });
    dispatch({
      type: MainActionTypes.SET_NOTIFICATION,
      payload: { type: "success", text: "Dane usługi zapisane" },
    });
    dispatch({
      type: ServicesActionTypes.SET_SERVICE_UPDATE_SUCCESSFUL,
      payload: true,
    });
  } catch (e) {
    dispatch({
      type: MainActionTypes.SET_NOTIFICATION,
      payload: { type: "error", text: "Wystąpił błąd" },
    });
  }
};

const postDetails = async (data, dispatch) => {
  try {
    const res = await api.post(`/services/details`, data);
    const res2 = await api.put(`/services/${data.registerId}`, {
      domainId: data.domainId,
      domainTypeId: data.domainTypeId,
    });
    dispatch({
      type: UpsertServiceFormActionTypes.SEND_FORM_DATA,
      payload: res,
    });
    dispatch({
      type: MainActionTypes.SET_NOTIFICATION,
      payload: { type: "success", text: "Dane usługi zapisane" },
    });
    dispatch({
      type: ServicesActionTypes.SET_SERVICE_UPDATE_SUCCESSFUL,
      payload: true,
    });
  } catch (e) {
    dispatch({
      type: MainActionTypes.SET_NOTIFICATION,
      payload: { type: "error", text: "Wystąpił błąd" },
    });
  }
};

const updateCard = (card, dispatch) => {
  const { id } = card;
  return api.put(`/services/cards/${id}`, card);
};

const postCard = (card, dispatch) => {
  return api.post(`/services/cards`, card);
};

const removeCard = (card, dispatch) => {
  const { id } = card;
  return api.delete(`/services/cards/${id}`, card);
};

const updateItem = async (item, dispatch) => {
  const { id } = item;
  return await api.put(`/services/items/${id}`, item);
};

const postItem = async (item, dispatch) => {
  return await api.post(`/services/items`, item);
};

const removeItem = async (item, dispatch) => {
  const { id } = item;
  return await api.delete(`/services/items/${id}`, item);
};

export const setServiceFormArrayData = (
  path: string[],
  index: number,
  name: string,
  value: any
) =>
  action(UpsertServiceFormActionTypes.SET_FORM_ARRAY_DATA, {
    value,
    index,
    path,
    name,
  });

export const setServiceFormSavedData = (formData: any) =>
  action(UpsertServiceFormActionTypes.SET_FORM_SAVED_DATA, formData);

export const setIsArrayTabInputValid = (
  path: string[],
  index: number,
  name: string,
  value: any
) =>
  action(UpsertServiceFormActionTypes.SET_FORM_VALIDATION_DATA, {
    value,
    index,
    path,
    name,
  });

export const reverseStoreChanges = (tab: string) =>
  action(UpsertServiceFormActionTypes.REVERT_CHANGES, { tab });

export const setAutocompleteSuggestions = (data: any) =>
  action(UpsertServiceFormActionTypes.SET_AUTOCOMPLETE_SUGGESTIONS, data);

export const autocompletePerson = ({ data, person }: any) =>
  action(UpsertServiceFormActionTypes.AUTOCOMPLETE_PERSON_DATA, {
    data,
    person,
  });
