import { Record } from "immutable";
import { ActionType } from "typesafe-actions";

import { RightNavBarActionTypes } from "src/action-types/RightNavBar";
// import { MainActionTypes } from 'src/action-types/Main';
// import { MainActions } from 'reducers/Main';
import * as actions from "src/actions/RightNavBar";
import { RightNavBarOption } from "src/actions/RightNavBar";

export type RightNavBarActions = ActionType<typeof actions>;

export interface IRightNavbarTab {
  id: RightNavBarOption;
  imageId: string;
  roles: any[];
}

export interface IRightNavBarState {
  selectedOption: actions.RightNavBarOption;
  isShown: boolean;
  isEditDisabled: boolean;
  wasChange: boolean;
  options: IRightNavbarTab[];
  analysesOptions: IRightNavbarTab[];
}

export type RightNavBarState = Record<IRightNavBarState>;

const RightNavBarState = Record<IRightNavBarState>({
  selectedOption: actions.RightNavBarOption.actions,
  isShown: false,
  isEditDisabled: false,
  wasChange: false,
  options: [
    { id: RightNavBarOption.actions, imageId: "right-nvb-actions", roles: ["secretary",'lawyer']},
    { id: RightNavBarOption.person, imageId: "right-nvb-person", roles: ["secretary",'lawyer']},
    { id: RightNavBarOption.description, imageId: "right-nvb-description", roles: ["secretary",'lawyer']},
    { id: RightNavBarOption.enemy, imageId: "right-nvb-enemy", roles: ["secretary",'lawyer']},
    { id: RightNavBarOption.offices, imageId: "right-nvb-offices", roles: ["secretary",'lawyer']},
    { id: RightNavBarOption.consequences, imageId: "right-nvb-consequences", roles: ["secretary",'lawyer']},
    { id: RightNavBarOption.condition, imageId: "right-nvb-condition", roles: ["secretary",'lawyer']},
    { id: RightNavBarOption.witnesses, imageId: "right-nvb-witnesses", roles: ["secretary",'lawyer']},
    { id: RightNavBarOption.additional, imageId: "right-nvb-additional", roles: ["secretary",'lawyer']},
    { id: RightNavBarOption.documentation, imageId: 'documentation', roles: ["secretary",'lawyer'] },
    // { id: RightNavBarOption.agreement, imageId: 'right-nvb-agreement' },
    // { id: RightNavBarOption.guardian, imageId: 'right-nvb-guardian' }
  ],
 

  analysesOptions: [
    
    { id: RightNavBarOption.actions, imageId: "right-nvb-actions", roles: ["secretary",'lawyer']},
    { id: RightNavBarOption.documentation, imageId: 'documentation', roles: ["secretary",'lawyer'] },
    { id: RightNavBarOption.description, imageId: "right-nvb-description", roles: ["secretary",'lawyer']},
    { id: RightNavBarOption.additional, imageId: "right-nvb-additional", roles: ["secretary",'lawyer']},
    
  ],
});

const initialState: RightNavBarState = new RightNavBarState();

export default function rightNavBar(
  state: RightNavBarState = initialState,
  action: RightNavBarActions
): RightNavBarState {
  switch (action.type) {
    case RightNavBarActionTypes.SELECT_OPTION:
      return state
        .set("selectedOption", action.payload)
        .set("isEditDisabled", false);
    case RightNavBarActionTypes.TOGGLE:
      return state.set("isShown", action.payload);
    case RightNavBarActionTypes.TOGGLE_EDIT:
      return state.set("isEditDisabled", action.payload);
    case RightNavBarActionTypes.TOGGLE_CHANGE:
      return state.set("wasChange", action.payload);
    default:
      return state;
  }
}
