import { SET_DOMAIN_TYPES } from "../actions/types";

const initialState = {
  domainTypesList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_DOMAIN_TYPES: {
      return {
        ...state,
        domainTypesList: action.payload,
      };
    }
    default:
      return state;
  }
}
