import { SET_ANALYZES, SET_ANALYSIS } from "../actions/types";

const initialState = {
  lawyersList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ANALYZES: {
      return {
        ...state,
        lawyersList: action.payload,
      };
    }
    case SET_ANALYSIS: {
      return {
        ...state,
        lawyer: action.payload,
      };
    }
    default:
      return state;
  }
}
