import React from "react";
import styles from "./Menu.module.scss";
import { NavLink } from "react-router-dom";
import User from "../../../services/User";
import classNames from "classnames";
import Tooltip from "@material-ui/core/Tooltip";

class Menu extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      completeProfile: false,
      completePrices: false,
    };
  }

  componentDidMount() {
    const { lawyer } = this.props;

    if (lawyer)
      this.setState({
        completeProfile:
          lawyer.description && lawyer.education && lawyer.imageId
            ? true
            : false,
        completePrices: this.props.completePrices,
      });
  }

  render() {
    const { toggleMenu, isOpen, user } = this.props;
    const { completeProfile, completePrices } = this.state;

    return (
      <nav
        className={classNames({
          [styles.Menu]: true,
          [styles.isOpen]: isOpen,
        })}
      >
        <ul>
          {user && user.role === "customer" && (
            <>
              <li>
                <Tooltip
                  title="Dziedziny prawa"
                  placement="right"
                  arrow
                  enterNextDelay={800}
                >
                  <NavLink to="/" activeClassName="active" onClick={toggleMenu}>
                    Dziedziny prawa
                  </NavLink>
                </Tooltip>
              </li>
              <li>
                <NavLink
                  to="/customer/calls"
                  activeClassName="active"
                  onClick={toggleMenu}
                  onClick={(e) => {}}
                >
                  Połączenia
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/customer/messages"
                  activeClassName="active"
                  onClick={toggleMenu}
                  onClick={(e) => {}}
                >
                  Wiadomości
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/customer/services"
                  activeClassName="active"
                  onClick={toggleMenu}
                  onClick={(e) => {}}
                >
                  Usługi
                </NavLink>
              </li>
              <li>
                <NavLink
                  to=""
                  activeClassName="active"
                  className={styles.disabled}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  Rozliczenia
                </NavLink>
              </li>
              <li>
                <NavLink
                  to=""
                  activeClassName="active"
                  className={styles.disabled}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  Profil
                </NavLink>
              </li>
              <li>
                <NavLink to="" activeClassName="active" onClick={User.logout}>
                  Wyloguj
                </NavLink>
              </li>
            </>
          )}

          {user && user.role === "lawyer" && (
            <>
              <li>
                <Tooltip
                  title="Niedostępne w wersji mobilnej"
                  placement="left"
                  enterTouchDelay={0}
                  enterDelay={0}
                  arrow
                >
                  <NavLink
                    to="/lawyer/clients"
                    activeClassName="active"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    className={styles.disabled}
                  >
                    Klienci
                  </NavLink>
                </Tooltip>
              </li>
              <li>
                <Tooltip
                  title="Niedostępne w wersji mobilnej"
                  placement="left"
                  enterTouchDelay={0}
                  enterDelay={0}
                  arrow
                >
                  <NavLink
                    to="/lawyer/services"
                    activeClassName="active"
                    className={styles.disabled}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Usługi
                  </NavLink>
                </Tooltip>
              </li>
              <li>
                <Tooltip
                  title="Niedostępne w wersji mobilnej"
                  placement="left"
                  enterTouchDelay={0}
                  enterDelay={0}
                  arrow
                >
                  <NavLink
                    to="/lawyer/calls"
                    activeClassName="active"
                    className={styles.disabled}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Połączenia
                  </NavLink>
                </Tooltip>
              </li>
              <li>
                <Tooltip
                  title="Niedostępne w wersji mobilnej"
                  placement="left"
                  enterTouchDelay={0}
                  enterDelay={0}
                  arrow
                >
                  <NavLink
                    to="/lawyer/messages"
                    activeClassName="active"
                    className={styles.disabled}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Wiadomości
                  </NavLink>
                </Tooltip>
              </li>
              <li>
                <Tooltip
                  title="Niedostępne w wersji mobilnej"
                  placement="left"
                  enterTouchDelay={0}
                  enterDelay={0}
                  arrow
                >
                  <NavLink
                    to="/lawyer/xxx"
                    activeClassName="active"
                    className={styles.disabled}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Dokumenty
                  </NavLink>
                </Tooltip>
              </li>
              <li>
                <Tooltip
                  title="Niedostępne w wersji mobilnej"
                  placement="left"
                  enterTouchDelay={0}
                  enterDelay={0}
                  arrow
                >
                  <NavLink
                    to="/lawyer/xxx"
                    activeClassName="active"
                    className={styles.disabled}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Pilne
                  </NavLink>
                </Tooltip>
              </li>
              <li>
                <Tooltip
                  title="Niedostępne w wersji mobilnej"
                  placement="left"
                  enterTouchDelay={0}
                  enterDelay={0}
                  arrow
                >
                  <NavLink
                    to="/lawyer/xxx"
                    activeClassName="active"
                    className={styles.disabled}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Przegląd panelu
                  </NavLink>
                </Tooltip>
              </li>
              <li>
                <NavLink
                  to="/lawyer/schedule"
                  activeClassName="active"
                  onClick={toggleMenu}
                  className={classNames({
                    [styles.disabled]: !completeProfile || !completePrices,
                  })}
                >
                  Harmonogram
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/lawyer/prices"
                  activeClassName="active"
                  onClick={toggleMenu}
                  className={classNames({
                    [styles.disabled]: !completeProfile,
                  })}
                >
                  Cennik
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/lawyer/profile"
                  activeClassName="active"
                  onClick={toggleMenu}
                >
                  Twój profil
                </NavLink>
              </li>
              <li>
                <NavLink to="" activeClassName="active" onClick={User.logout}>
                  Wyloguj się
                </NavLink>
              </li>
            </>
          )}

          {user && user.role === "dealer" && (
            <>
              <li>
                <NavLink
                  to="/partner/clients"
                  activeClassName="active"
                  onClick={toggleMenu}
                >
                  Klienci
                </NavLink>
              </li>
              <li>
                <NavLink to="" activeClassName="active" onClick={User.logout}>
                  Wyloguj się
                </NavLink>
              </li>
            </>
          )}

          {!user && (
            <>
              <li>
                <NavLink to="/" activeClassName="active" onClick={toggleMenu}>
                  Dziedziny prawa
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/login"
                  activeClassName="active"
                  onClick={toggleMenu}
                >
                  Zaloguj
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/lawyer/login"
                  activeClassName="active"
                  onClick={toggleMenu}
                >
                  Strefa Prawnika
                </NavLink>
              </li>
            </>
          )}
        </ul>
      </nav>
    );
  }
}

Menu.defaultProps = {};

export default Menu;
