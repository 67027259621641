import api from "./Api";

import { setNotification } from "src/actions/Main";

function login(email, password, setError, setLogged, setOK, accountType) {
  api
    .post("auth/login", {
      email: email,
      password: password,
    })
    .then((response) => {
      if (response.data.success) {
        //const isActive = response.data.data.isActive;
        const isActive = true;
        if (setOK) {
          getUserData(
            getDataFromToken(response.data.data.token).id,
            response.data.data.token
          ).then((user) => {
            if (
              !accountType ||
              (accountType && accountType === user.role) ||
              (accountType === "lawyer" && user.role === "secretary") ||
              (accountType === "lawyer" && user.role === "dealer") // logowanie sekretarki w ekranie logowania prawnika
            ) {
              setOK(true);
            }
          });
        } else
          getUserData(
            getDataFromToken(response.data.data.token).id,
            response.data.data.token
          ).then((user) => {
            if (user.isVerified) {
              if (user.role === "lawyer" && !isActive) {
                if (setError) setError("Konto nieaktywne.");
                return;
              }

              localStorage.setItem("token", response.data.data.token);
              if (setError) setError(null);
              if (setLogged) setLogged(true);
            } else {
              if (setError)
                setError(
                  "Konto nieaktywne. Wejdź na pocztę i kliknij w link aktywacyjny."
                );
              return;
            }
          });
      }
    })
    .catch(function (error) {
      if (error.response) {
        //if(setError) setError(error.response.data.error);
        if (setOK) setOK(false);
      }
    });
}

function logout() {
  localStorage.removeItem("token");
  localStorage.clear();
  window.location.href = "/";
}

function getUser(resolve) {
  let token = getToken();
  if (token) {
    try {
      let userData = getDataFromToken(token);

      // api zwraca niepoprawną datę wygaśnięcia tokenu

      // if(userData.exp > Date.now()){
      //     return userData;
      // }
      userData.token = token;

      if (resolve) resolve(userData);

      return userData;
    } catch (error) {
      return null;
    }
  }

  return null;
}

function getToken() {
  return localStorage.getItem("token") ? localStorage.getItem("token") : null;
}

function getDataFromToken(token) {
  //console.log(token, JSON.parse(atob(token.split('.')[1])));
  return JSON.parse(atob(token.split(".")[1]));
}

function getRole() {
  api.get("/users/" + getUser().id).then((response) => {
    if (response.status === 200 && response.data.success === true) {
      let user = response.data.data;

      return user.role;
    }
  });
}

function getUserData(id, token) {
  token = token || getToken();
  if (typeof id == "number") {
    return api
      .get("/users/" + id, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.status === 200 && response.data.success === true) {
          return response.data.data;
        }
      });
  } else if (getUser()) {
    const token = getUser().token;
    return api
      .get("/users/" + getUser().id, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.status === 200 && response.data.success === true) {
          return response.data.data;
        }
      });
  } else return null;
}

function getLayerData(lawyerId) {
  if (lawyerId) {
    return api.get("/lawyers/" + lawyerId).then((response) => {
      if (response.data.success === true) {
        return response.data.data;
      }
    });
  } else return null;
}

export const User = {
  login,
  logout,
  getUser,
  getToken,
  getRole,
  getUserData,
  getLayerData,
};
export default User;
