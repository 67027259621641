import * as languages from "src/helpers/Translations/languages";

const listOfLanguages = Object.assign(languages);

const translationsMap = Object.keys(listOfLanguages).map((key) => {
  try {
    return require(`./${listOfLanguages[key]}`);
  } catch (err) {
    console.warn(err.message);
  }
});

let result = {};
for (const item of translationsMap) {
  result = Object.assign(result, item);
}

export default result;
